import { AUTH, getCurrentUser, User } from '@netcurio/frontend-common'

import React, { createContext, useEffect, useState } from 'react'
interface UserContextProps {
	user: User | null
	setUser: (user: User | null) => void
}

export const UserContext = createContext<UserContextProps>({
	user: null,
	setUser: () => {
		console.log('empty setUser')
	}
})

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
	const [user, updateUser] = useState<User | null>(getCurrentUser())

	useEffect(() => {
		const localUser = localStorage.getItem(AUTH.NETCURIO_USER)
		if (localUser) {
			const parsedUser = JSON.parse(localUser)
			updateUser(parsedUser)
		}
	}, [])

	const setUser = (user: User | null) => {
		if (user) {
			updateUser(user)
			localStorage.setItem(AUTH.NETCURIO_USER, JSON.stringify(user))
		}
	}

	return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>
}
