export enum InvoiceStatusEnum {
	PAID = 'paid',
	CANCELLED = 'cancelled',
	ERROR = 'error',
	MISSING_LINK = 'missing_link',
	PAYMENT_SCHEDULED = 'payment_scheduled',
	VOIDED = 'voided',
	IN_PROCESS = 'in_process',
	IN_APPROVAL = 'in_approval'
}
