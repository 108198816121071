import { getCurrentUser } from '@netcurio/frontend-common'
import { Auth } from 'aws-amplify'
import styles from '../components/HeaderTable/HeaderTable.module.scss'
import Formatter from './formatter'

/**
 * @param fieldsList <string>: Array of column's titles; used to be iterated
 * @param field <string>: Code-name for the clicked title to show/hide its filter
 * @param selectedClass <>: Onclick style name to change active filter's background-color
 * @commentaryFromDeveloper: The following functions, updateHeaderColors & updateHeaderBottomRadius are separated,
 * since they're called on different moments, and use different approaches to modify their own or a sibling's styles
 */
const updateHeaderColors = (fieldsList, field, selectedClass) => {
	fieldsList.forEach((value) => {
		if (value.getParameterField() === field) {
			value.setSelectedFieldClass(selectedClass)
		} else {
			value.setSelectedFieldClass('')
		}
	})
}

const updateHeaderBottomRadius = (selectedClass) => {
	let headers = document.getElementsByClassName(styles.textHeaderOrderList)
	for (let i = 0; i < headers.length; i++) {
		if (headers[i + 1]) {
			headers[i + 1].style.borderBottomLeftRadius = '0px'
		}
		if (headers[i - 1]) {
			headers[i - 1].style.borderBottomRightRadius = '0px'
		}
		if (headers[i].classList.contains(selectedClass)) {
			if (headers[i + 1]) {
				headers[i + 1].style.borderBottomLeftRadius = '5px'
			}
			if (headers[i - 1]) {
				headers[i - 1].style.borderBottomRightRadius = '5px'
			}
		}
	}
}

const handleFilterKeyDown = (
	evt,
	callDataList,
	emptyFilterField,
	actualField,
	filterValue,
	minRange,
	maxRange
) => {
	if (evt !== undefined && evt.key === 'Enter' && evt.target.value !== '') {
		callDataList('', '', actualField, evt.target.value)
		emptyFilterField()
	} else if (evt === 'onClickText' && filterValue) {
		callDataList('', '', actualField, filterValue)
		emptyFilterField()
	} else if (evt === 'onClickNumeric' && (minRange || maxRange)) {
		callDataList('', '', actualField, undefined)
		emptyFilterField()
	}
}

const handlePagination = (
	dataClassName,
	totalRows,
	rowsActual,
	filteringFunction,
	setActivePagination,
	setShowBarLoader
) => {
	setShowBarLoader(false)
	const scrollPosition = document.getElementsByClassName(dataClassName)[0]
	if (totalRows > rowsActual) {
		if (
			scrollPosition.scrollTop > 0 &&
			scrollPosition.offsetHeight + scrollPosition.scrollTop >= scrollPosition.scrollHeight - 1
		) {
			setShowBarLoader(true)
			if (setActivePagination) setActivePagination(true)
			else filteringFunction(rowsActual, '', '', '', undefined, undefined, undefined, 'none')
		}
	}
}

const generateFiltersToApply = (
	typeFilterActual,
	valueFilterActual,
	initRangeActual,
	finalRangeActual,
	columnFilterActual,
	filterRemove,
	sortField,
	stateDataFilters,
	filtersOfTypeStatus
) => {
	let objectForStateUpdate = {}
	let filterToApply = []
	let objectFilterToApply
	let applyFilter = false
	let filterObjectToRemove
	switch (typeFilterActual) {
		case 'wildcard':
		case 'exact_match':
		case 'boolean':
			if (valueFilterActual !== undefined) {
				applyFilter = true
			}
			break
		case 'numeric':
		case 'date':
			if (initRangeActual !== undefined || finalRangeActual !== undefined) {
				applyFilter = true
			}
			break
		case 'hasRelation':
			if (valueFilterActual !== undefined) {
				valueFilterActual = valueFilterActual ? 'true' : 'false'
				applyFilter = true
			}
			break
	}
	if (filterRemove === 'removeFilters') {
		stateDataFilters = {}
	} else {
		filterObjectToRemove = stateDataFilters[filterRemove]
		delete stateDataFilters[filterRemove]
	}
	if (applyFilter || Object.keys(stateDataFilters).length > 0) {
		let dataFilters = { ...stateDataFilters }
		if (!filterRemove) {
			if (!sortField) {
				objectFilterToApply = {
					column_name: columnFilterActual,
					type: typeFilterActual,
					value:
						typeFilterActual === 'date' || typeFilterActual === 'numeric'
							? undefined
							: valueFilterActual,
					init_range: typeFilterActual === 'numeric' ? initRangeActual : undefined,
					final_range: typeFilterActual === 'numeric' ? finalRangeActual : undefined,
					init_date: typeFilterActual === 'date' ? initRangeActual : undefined,
					final_date: typeFilterActual === 'date' ? finalRangeActual : undefined
				}
				if (
					typeFilterActual !== 'exact_match' ||
					(objectFilterToApply.value === undefined &&
						typeFilterActual === 'date' &&
						(initRangeActual !== undefined || finalRangeActual !== undefined))
				) {
					dataFilters[columnFilterActual] = objectFilterToApply
				} else if (valueFilterActual !== '' && valueFilterActual !== undefined) {
					objectForStateUpdate = {
						...objectForStateUpdate,
						...{
							filtersOfTypeStatus: {
								...filtersOfTypeStatus,
								[columnFilterActual]: {
									...filtersOfTypeStatus[columnFilterActual],
									dataFilter: filterRemove,
									numSelectOptions:
										filtersOfTypeStatus[columnFilterActual].numSelectOptions + 1
								}
							}
						}
					}
					dataFilters[valueFilterActual] = objectFilterToApply
				}
			}
		} else if (existFilter(filtersOfTypeStatus, filterRemove)) {
			for (let key of Object.keys(filtersOfTypeStatus)) {
				if (
					filtersOfTypeStatus[key].list.find((status) => status.selectedParameter === filterRemove)
				) {
					filtersOfTypeStatus[key].numSelectOptions = filtersOfTypeStatus[key].numSelectOptions - 1
					filtersOfTypeStatus[key].dataFilter = filterRemove
				}
			}

			objectForStateUpdate = {
				...objectForStateUpdate,
				...{
					filtersOfTypeStatus: filtersOfTypeStatus
				}
			}
		} else if (filterObjectToRemove !== undefined) {
			if (filterObjectToRemove.type === 'boolean') {
				objectForStateUpdate = {
					...objectForStateUpdate,
					...{
						dataStatusFilter: 'return'
					}
				}
			}
		}
		for (const keyFilter in dataFilters) {
			if (keyFilter !== filterRemove) {
				filterToApply.push(dataFilters[keyFilter])
			}
		}
		objectForStateUpdate = {
			...objectForStateUpdate,
			...{ dataFilters: dataFilters, filterContainerBar: true }
		}
	} else {
		for (let key of Object.keys(filtersOfTypeStatus)) {
			filtersOfTypeStatus[key].numSelectOptions = 0
			filtersOfTypeStatus[key].dataFilter = 'return'
		}
		objectForStateUpdate = {
			...objectForStateUpdate,
			...{
				filterContainerBar: false,
				dataFilters: {},
				dataFiltersArray: [],
				dataStatusFilter: 'return'
			},
			...{
				filtersOfTypeStatus: filtersOfTypeStatus
			}
		}
	}
	objectForStateUpdate = {
		...objectForStateUpdate,
		...{ dataFiltersArray: filterToApply, deleteRange: false }
	}
	return { filterToApply, objectForStateUpdate }
}

const closeFilterContainerBar = (filteringFunction, initialSort) => {
	filteringFunction(0, initialSort, '', initialSort, undefined, undefined, undefined, 'removeFilters')
}

const deleteFilterActive = (indexObject, dataFilters, closeFilterContainerBarFunction, filteringFunction) => {
	if (Object.keys(dataFilters).length < 2) {
		closeFilterContainerBarFunction()
	} else {
		filteringFunction(0, '', '', '', '', '', '', indexObject)
	}
}

const applyTimeZoneDate = (item, index, copyfilterToApply) => {
	if (item.type === 'date') {
		if (item.init_date !== undefined) {
			copyfilterToApply[index].init_date = Formatter.initDateFormat(item.init_date)
		}
		if (item.final_date !== undefined) {
			copyfilterToApply[index].final_date = Formatter.finalDateFormat(item.final_date)
		}
	}
}

function existFilter(filtersOfTypeStatus, filterRemove) {
	for (let key of Object.keys(filtersOfTypeStatus)) {
		if (filtersOfTypeStatus[key].list.find((status) => status.selectedParameter === filterRemove)) {
			return true
		}
	}
	return false
}

async function generateReportList(body, reportUrl) {
	const currentUser = getCurrentUser()

	let token = await Auth.currentAuthenticatedUser()
		.then((user) => {
			return user.signInUserSession.idToken.jwtToken
		})
		.catch((err) => {
			console.error(err)
		})

	return new Promise((resolve, reject) => {
		fetch(`/api/reporting/${reportUrl}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json; charset=UTF-8',
				Accept: 'application/json',
				authorization: 'Bearer ' + token,
				'current-company': currentUser ? currentUser.company.rfc : undefined
			},
			body: body
		})
			.then((result) => {
				resolve(result)
			})
			.catch((error) => {
				reject(error.response)
			})
	})
}

export default {
	updateHeaderColors: updateHeaderColors,
	handleFilterKeyDown: handleFilterKeyDown,
	handlePagination: handlePagination,
	generateFiltersToApply: generateFiltersToApply,
	closeFilterContainerBar: closeFilterContainerBar,
	deleteFilterActive: deleteFilterActive,
	updateHeaderBottomRadius: updateHeaderBottomRadius,
	applyTimeZoneDate: applyTimeZoneDate,
	generateReportList: generateReportList
}
