import {
	Comment,
	PURCHASE,
	Roles,
	Status,
	URLS,
	User,
	currencyFormatter,
	dateFormatter,
	parseTimestampToTimeZone
} from '@netcurio/frontend-common'
import {
	CommentSectionSize,
	CommentSectionVariant,
	CommentsSection,
	FileViewerModal,
	NetcurioAlert,
	NetcurioButton,
	NetcurioGrid,
	NetcurioIcons,
	StatusBadges,
	TotalContainerBar,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import DefaultClient, { ApolloQueryResult, NormalizedCacheObject } from 'apollo-boost'
import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import { render } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { RouterChildContext, useHistory, useLocation } from 'react-router-dom'
import { AuthenticatedHeader } from '../../../components/AuthenticatedHeader/AuthenticatedHeader'
import { useEnvironment } from '../../../hooks/useEnvironment'
import { ErrorWrapper, GoodReceipt, Invoice, PurchaseOrder, PurchaseOrderItem } from '../../../types'
import { connection } from '../../../utilities/connection'
import Constants from '../../../utilities/constants'
import { showErrorComponent } from '../../../utilities/errorCode'
import { expiredToken } from '../../../utilities/expiredToken'
import Formatter from '../../../utilities/formatter'
import { getUrlParameter } from '../../../utilities/getUrlParameter'
import { useDelayUnmount } from '../../../utilities/useDelayUnmount'
import UserInformation from '../../../utilities/userInformation'
import { redirectByStatus } from '../../../utilities/validateUrlRoles'
import { NewInvoiceModal } from '../../invoices/NewInvoiceModal/NewInvoiceModal'
import { DetailField } from '../DetailField/DetailField'
import {
	CREATE_PURCHASE_ORDER_COMMENT,
	INVOICES_DETAIL,
	PURCHASE_ORDER_COMMENTS,
	PURCHASE_ORDER_DETAIL
} from '../graphQl'
import { getVisualStatusPoDetail } from '../helpers/getVisualStatusPoDetail'
import {
	TypesOfStatusMessagesPurchaseOrderDetail,
	purchaseOrderDetialAlertMessages
} from '../helpers/visualMessagePoDetail'
import { defaultValuesPurchaseOrder } from '../utilities/defaultValues'
import { GoodsReceiptAndInvoicesModule } from './GoodsReceiptAndInvoicesModule/GoodsReceiptAndInvoicesModule'
import { ItemsList } from './ItemsList/ItemsList'
import { CancelNewGRModal } from './Modals/CancelNewGRModal/CancelNewGRModal'
import { ErrorModal } from './Modals/ErrorModal/ErrorModal'
import { RejectPendingMessagePODetail } from './Modals/RejectPendingMessagePODetail/RejectPendingMessagePODetail'
import { NewGoodsReceipt } from './NewGoodsReceipt/NewGoodsReceipt'
import { RelatedInvoiceDetail } from './RelatedInvoiceDetail/RelatedInvoiceDetail'
import { PageManager } from './downloadablePoStructure/pageManager'
import styles from './purchaseOrderDetail.module.scss'

interface IFileViewer {
	open: boolean
	titleText: string
	fileSrc: string
}

export const PurchaseOrderDetail = () => {
	const { t } = useTranslation()
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search)
	const history: RouterChildContext['router']['history'] = useHistory()
	const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder>(defaultValuesPurchaseOrder)
	const [dataTable, setDataTable] = useState<Array<PurchaseOrderItem>>([])
	const [errorMessage, setErrorMessage] = useState<ErrorWrapper | undefined>()
	const [dataComments, setDataComments] = useState<Array<Comment>>([])
	const [isCommentsSectionExtended, setIsCommentsSectionExtended] = useState<boolean>(false)
	const [existMessagePending, setExistMessagePending] = useState<boolean>(false)
	const [dataGoodsReceipts, setDataGoodsReceipts] = useState<Array<GoodReceipt>>([])
	const [dataInvoices, setDataInvoices] = useState<Array<Invoice>>([])
	const [statusMessageGeneralPoDetail] = useState<TypesOfStatusMessagesPurchaseOrderDetail>(
		TypesOfStatusMessagesPurchaseOrderDetail.Default
	)
	const [fileViewer, setFileViewer] = useState<IFileViewer>({ open: false, titleText: '', fileSrc: '' })
	const [openPendingMessageModal, setOpenPendingMessageModal] = useState<boolean>(false)
	const [showNewInvoiceModal, setShowNewInvoiceModal] = useState<boolean>(false)
	const [editGoodReceive, setEditGoodReceive] = useState<boolean>(false)
	const [showInfoInvoice, setShowInfoInvoice] = useState<boolean>(false)
	const [exitGRModal, setExitGRModal] = useState<boolean>(false)
	const [informationInvoice, setInformationInvoice] = useState<Invoice>(undefined)
	const [showTableEntries, setShowTableEntries] = useState<boolean>(false)
	const [cancelGRModal, setCancelGRModal] = useState<boolean>(false)
	const [showDetailGoodReceive, setShowDetailGoodReceive] = useState<boolean>(false)
	const [showDiv, hideStyle] = useDelayUnmount(!isCommentsSectionExtended, 1000)
	const address = `${purchaseOrder?.delivery_address_line_1 ?? ''} ${
		purchaseOrder?.delivery_address_line_2 ?? ''
	} ${purchaseOrder?.delivery_address_state ?? ''} ${purchaseOrder.delivery_address_postal_code ?? ''} ${
		purchaseOrder?.delivery_address_country ?? ''
	}`
	const POInformationForStatusSection = {
		purchaseOrderType: purchaseOrder.type?.key,
		currentStatus: (purchaseOrder?.status as Status)?.key,
		createdDate: dateFormatter.format(parseTimestampToTimeZone(purchaseOrder.created_at)),
		confirmedDate: dateFormatter.format(parseTimestampToTimeZone(purchaseOrder.date_confirmed)),
		rejectedDate: dateFormatter.format(parseTimestampToTimeZone(purchaseOrder.date_rejected)),
		lastDeliveredDate: dateFormatter.format(parseTimestampToTimeZone(purchaseOrder.date_last_delivery))
	}
	const userRole = UserInformation.getCompanyRole()
	const companyData = userRole === Roles.CUSTOMER ? purchaseOrder?.supplier : purchaseOrder?.customer
	const { environment } = useEnvironment()
	const { S3_BUCKET } = environment ? environment : { S3_BUCKET: '' }

	useEffect(() => {
		getPurchaseOrderDetail()
	}, [])

	useEffect(() => {
		if (purchaseOrder) {
			const { attachment } = purchaseOrder
			const { PO_FOLDER } = Constants.FOLDER
			if (attachment) {
				const fileName = `${attachment.toLowerCase()}.pdf`
				const fileSrc = `${S3_BUCKET}${PO_FOLDER}/${fileName}`
				const titleText = t('pdfAttachmentText')
				setFileViewer((state) => ({ ...state, titleText, fileSrc }))
			}
		}
	}, [purchaseOrder, S3_BUCKET, t])

	const getPurchaseOrderDetail = () => {
		showLoadingSpinner()
		const purchaseOrderId = searchParams.get('order')
		const customerID = searchParams.get('customer')
		client
			.query({
				query: PURCHASE_ORDER_DETAIL,
				variables: {
					id: purchaseOrderId,
					customer: customerID
				},
				fetchPolicy: 'no-cache'
			})
			.then((result: ApolloQueryResult<{ PurchaseOrderDetail: PurchaseOrder }>) => {
				const paramsToUrl = `?order='${purchaseOrderId}&customer=${customerID}`
				redirectByStatus(Constants.LISTS.PO, result.data.PurchaseOrderDetail.status.key, paramsToUrl)
				if (result.data.PurchaseOrderDetail) {
					setPurchaseOrder(result.data.PurchaseOrderDetail)
					setDataTable(result.data.PurchaseOrderDetail.items)
					setDataComments(result.data.PurchaseOrderDetail.comments)
					setDataGoodsReceipts(result.data.PurchaseOrderDetail.goodsReceipts)
					setDataInvoices(result.data.PurchaseOrderDetail.invoices)
				} else {
					const errorCode = showErrorComponent(undefined)
					setErrorMessage({
						code: errorCode
					})
				}
			})
			.catch(errorHandler)
			.finally(() => hideLoadingSpinner())
	}

	const errorHandler = (error: Error) => {
		const errorCode = showErrorComponent(error)
		if (!expiredToken(errorCode)) {
			setErrorMessage({
				code: errorCode,
				message: error?.message
			})
		}
		hideLoadingSpinner()
	}

	const handleCommentsSectionSizeChange = (commentsSectionSize: CommentSectionSize): void => {
		setIsCommentsSectionExtended(commentsSectionSize === CommentSectionSize.Extended)
	}

	const onChangeCommentMessage = (pendingComment: string) => {
		setExistMessagePending(!!pendingComment)
	}

	const addNewComment = (newCommentData: { text: string }) => {
		const dataNewComment = {
			purchase_order: searchParams.get('order'),
			customer: searchParams.get('customer'),
			text: newCommentData.text
		}
		client
			.mutate({
				mutation: CREATE_PURCHASE_ORDER_COMMENT,
				variables: dataNewComment
			})
			.then(() => {
				client
					.query({
						query: PURCHASE_ORDER_COMMENTS,
						variables: {
							id: searchParams.get('order'),
							customer: searchParams.get('customer')
						},
						fetchPolicy: 'no-cache'
					})
					.then((result: ApolloQueryResult<{ PurchaseOrder: { comments: Array<Comment> } }>) => {
						setDataComments(result.data.PurchaseOrder.comments)
					})
					.catch(errorHandler)
			})
			.catch(errorHandler)
			.finally(() => setExistMessagePending(false))
	}

	const openNewGoodReceiptTable = () => {
		setShowTableEntries(true)
	}

	const openInvoiceDetail = (uuid: string) => {
		getInformationInvoice(uuid)
	}

	const getInformationInvoice = (idInvoice: string) => {
		showLoadingSpinner()
		client
			.query({
				query: INVOICES_DETAIL,
				variables: {
					uuid: idInvoice
				}
			})
			.then((result: ApolloQueryResult<{ Invoice: Invoice }>) => {
				setInformationInvoice(result.data.Invoice)
				hideLoadingSpinner()
				setShowInfoInvoice(true)
			})
			.catch(errorHandler)
	}

	const downloadPdfPo = () => {
		if (purchaseOrder?.attachment) {
			setFileViewer({ ...fileViewer, open: true })
		} else {
			// TODO: this part will need to be refactored
			render(
				<PageManager informationPurchaseOrder={purchaseOrder} informationTable={dataTable} />,
				document.getElementById('parentModal'),
				() => {
					const mainViewClass = document.getElementsByClassName(
						'mainViewHome'
					) as HTMLCollectionOf<HTMLElement>
					mainViewClass[0].style.display = 'none'
				}
			)
		}
	}

	const changeStatus = () => {
		let statusText = Constants.STATUS.CONFIRMED
		let confirmedAmount = 0
		let allRejected = true
		for (const item of dataTable) {
			confirmedAmount += item.confirmed_amount || 0
			if (item.confirmed_amount !== item.requested_amount) {
				statusText = PURCHASE.HALF_CONFIRMED
			}
			if ((item.status as Status).key !== Constants.STATUS.REJECTED) {
				allRejected = false
			}
		}
		if (allRejected) {
			statusText = Constants.STATUS.REJECTED
		} else if (!confirmedAmount) {
			statusText = Constants.SYMBOL.NA
		}
		return statusText
	}

	const extraRequestParams = {
		reference: getUrlParameter('order') || undefined,
		referenceType: Constants.DOCUMENT_TYPE.PURCHASE_ORDER
	}

	const resetAllDataPO = () => {
		setEditGoodReceive(false)
		setShowTableEntries(false)
		setCancelGRModal(false)
		setExitGRModal(false)
		getPurchaseOrderDetail()
	}

	const hideEntriesRecord = () => {
		if (editGoodReceive) {
			setCancelGRModal(true)
		} else {
			setShowTableEntries(false)
			setCancelGRModal(false)
			setExitGRModal(false)
		}
	}

	const editGoodReceipt = () => {
		setEditGoodReceive(true)
	}

	const acceptActionModal = () => {
		if (cancelGRModal) {
			setShowTableEntries(false)
			setCancelGRModal(false)
			setEditGoodReceive(false)
			setExitGRModal(false)
		} else {
			history.push(URLS.PO_LIST)
		}
	}

	const showEntriesRecord = () => {
		setShowTableEntries(true)
	}

	const getLabelsByUserRole = (role: string, label: string) => {
		const rolesWithLabels = {
			[Roles.SUPPLIER]: {
				rfcText: t('rfcCustomerText'),
				headerText: t('customerOrderHeader'),
				userText: t('editBy')
			},
			[Roles.CUSTOMER]: {
				rfcText: t('rfcSupplierText'),
				headerText: t('supplierText'),
				userText: t('createByText')
			}
		}
		return rolesWithLabels[role][label]
	}
	const closePDF = () => {
		setFileViewer({ ...fileViewer, open: false })
	}

	const mapTotalFields = () => {
		if (purchaseOrder.advance_percentage && purchaseOrder.advance_amount) {
			return {
				currencyText: purchaseOrder.currency,
				advanceAmount: purchaseOrder.advance_amount
					? currencyFormatter.format(purchaseOrder.advance_amount)
					: 0,
				advancePercentage: purchaseOrder.advance_percentage
					? (purchaseOrder.advance_percentage * 100).toString() + '%'
					: 0,
				pendingAmortization:
					purchaseOrder.pending_amortization !== null
						? currencyFormatter.format(Number(purchaseOrder.pending_amortization))
						: '-'
			}
		} else {
			return { currencyText: purchaseOrder.currency }
		}
	}

	return (
		<NetcurioGrid container alignContent="flex-start" justifyContent="flex-start">
			<NetcurioGrid item alignItems="center" xs={12}>
				<AuthenticatedHeader>
					<div>
						<NetcurioButton
							color="error"
							variant={'outlined'}
							onClick={() => {
								if (showInfoInvoice || editGoodReceive) {
									setShowInfoInvoice(false)
									setExitGRModal(!showInfoInvoice)
								} else if (existMessagePending) {
									setOpenPendingMessageModal(true)
								} else {
									history.push(URLS.PO_LIST)
								}
							}}
							size="small"
							endIcon={<NetcurioIcons.ArrowBack />}
						>
							<span> {t(showInfoInvoice ? 'comeBackTextModal' : 'comebackListText')} </span>
						</NetcurioButton>
						<NetcurioButton
							color="primary"
							variant={'outlined'}
							onClick={downloadPdfPo}
							size="small"
							endIcon={<NetcurioIcons.Visibility />}
						>
							<span> {t('viewPdf')} </span>
						</NetcurioButton>
					</div>
					<div></div>
				</AuthenticatedHeader>
			</NetcurioGrid>
			{showInfoInvoice ? (
				<RelatedInvoiceDetail
					invoiceInformation={informationInvoice}
					setShowNewInvoiceModal={setShowNewInvoiceModal}
					allInvoices={dataInvoices}
					openInvoiceDetail={openInvoiceDetail}
					setShowInfoInvoice={setShowInfoInvoice}
				/>
			) : (
				<NetcurioGrid container alignItems="flex-start">
					<NetcurioGrid
						item
						sm={showDetailGoodReceive ? 9 : 8}
						md={showDetailGoodReceive ? 9 : 9}
						xs={showDetailGoodReceive ? 9 : 8}
						lg={showDetailGoodReceive ? 9 : 9}
						xl={showDetailGoodReceive ? 9 : 10}
						height="100%"
					>
						<NetcurioGrid container className={styles.purchaseOrderBackgroundInformationSection}>
							<NetcurioGrid item xs={12} className={styles.card}>
								<div>
									<span>{t('purchaseOrderText')}</span>
									<span>{Formatter.id(purchaseOrder.id)}</span>
								</div>
								<div>
									<span>{t(purchaseOrder.type?.key)}</span>
								</div>
							</NetcurioGrid>
							<NetcurioGrid item xs={12} className={styles.purchaseOrderInformationSection}>
								<NetcurioGrid container className={styles.statusSection}>
									<StatusBadges
										statusArray={getVisualStatusPoDetail(
											POInformationForStatusSection,
											changeStatus()
										)}
									/>
									<NetcurioGrid item alignSelf="center" margin="auto">
										{purchaseOrderDetialAlertMessages[statusMessageGeneralPoDetail]
											.message && (
											<NetcurioAlert
												severity={
													purchaseOrderDetialAlertMessages[
														statusMessageGeneralPoDetail
													].variant
												}
												icon={false}
											>
												{t(
													purchaseOrderDetialAlertMessages[
														statusMessageGeneralPoDetail
													].message
												)}
											</NetcurioAlert>
										)}
									</NetcurioGrid>
								</NetcurioGrid>
								<NetcurioGrid container className={styles.purchaseOrderContainer}>
									<NetcurioGrid item xs={3}>
										<DetailField
											title={getLabelsByUserRole(userRole, 'userText')}
											information={`${(purchaseOrder?.created_by as User)?.name} ${
												(purchaseOrder?.created_by as User)?.lastname
											}`}
										/>
										<DetailField
											title={getLabelsByUserRole(userRole, 'headerText')}
											information={companyData?.name}
											tooltip={companyData.name}
										/>
									</NetcurioGrid>
									<NetcurioGrid item xs={3}>
										<DetailField
											title={getLabelsByUserRole(userRole, 'rfcText')}
											information={companyData.rfc}
										/>
										<DetailField
											title={t('addressDelivery')}
											information={address}
											tooltip={address}
										/>
									</NetcurioGrid>
									<NetcurioGrid item xs={3}>
										<DetailField
											title={t('createDateText')}
											information={dateFormatter.format(
												parseTimestampToTimeZone(purchaseOrder?.created_at)
											)}
										/>
										<DetailField
											title={t('branchOfficeOrderHeader')}
											information={purchaseOrder?.branch_office ?? '-'}
										/>
									</NetcurioGrid>
									<NetcurioGrid item xs={3}>
										<DetailField
											title={t('customerReferenceTitle')}
											information={purchaseOrder?.customer_reference ?? '-'}
										/>
										<DetailField
											title={t('userReference')}
											information={purchaseOrder?.user_reference ?? '-'}
										/>
									</NetcurioGrid>
								</NetcurioGrid>
							</NetcurioGrid>
						</NetcurioGrid>
						<NetcurioGrid
							container
							justifyContent={'flex-start'}
							alignItems={'center'}
							alignContent={'start'}
							className={styles.listContainer}
							marginTop="1rem"
						>
							{showTableEntries ? (
								<NewGoodsReceipt
									resetAllDataPO={resetAllDataPO}
									hideEntriesRecord={hideEntriesRecord}
									editGoodReceipt={editGoodReceipt}
								/>
							) : (
								<ItemsList dataTable={dataTable} informationPurchaseOrder={purchaseOrder} />
							)}
						</NetcurioGrid>
					</NetcurioGrid>
					<NetcurioGrid
						item
						paddingLeft="1rem"
						height="100%"
						display="flex"
						flexDirection="column"
						gap="1.5rem"
						xs={showDetailGoodReceive ? 3 : 4}
						sm={showDetailGoodReceive ? 3 : 4}
						md={showDetailGoodReceive ? 3 : 3}
						lg={showDetailGoodReceive ? 3 : 3}
						xl={showDetailGoodReceive ? 3 : 2}
					>
						{!showDetailGoodReceive && (
							<>
								{purchaseOrder?.type?.key !== PURCHASE.PURCHASE_ORDER_TYPES.CONSIGNMENT &&
									showDiv && (
										<NetcurioGrid
											item
											className={classNames(styles.totalContainerConsumption, {
												[styles.transitionHide]: hideStyle
											})}
										>
											<TotalContainerBar
												title="total"
												fields={mapTotalFields()}
												total={{
													totalDotText: currencyFormatter.format(
														purchaseOrder.total
													)
												}}
											/>
										</NetcurioGrid>
									)}
								<NetcurioGrid
									item
									className={classNames(styles.commentSection, {
										[styles.grownCommentsSection]: isCommentsSectionExtended
									})}
								>
									<CommentsSection
										comments={dataComments}
										variant={CommentSectionVariant.WithBody}
										onSizeChange={(e: CommentSectionSize) =>
											handleCommentsSectionSizeChange(e)
										}
										onAddComment={addNewComment}
										onChange={onChangeCommentMessage}
									/>
								</NetcurioGrid>
							</>
						)}

						{!isCommentsSectionExtended && (
							<GoodsReceiptAndInvoicesModule
								typePO={purchaseOrder?.type?.key}
								statusPO={purchaseOrder?.status?.key}
								dataGoodsReceipts={dataGoodsReceipts}
								dataInvoices={dataInvoices}
								newGoodReceipt={openNewGoodReceiptTable}
								openInvoiceDetail={openInvoiceDetail}
								setShowNewInvoiceModal={setShowNewInvoiceModal}
								setShowDetailGoodReceive={setShowDetailGoodReceive}
								showDetailGoodReceive={showDetailGoodReceive}
								showEntriesRecord={showEntriesRecord}
								statusPurchaseOrder={purchaseOrder?.status}
							/>
						)}
					</NetcurioGrid>
				</NetcurioGrid>
			)}
			<NewInvoiceModal
				open={showNewInvoiceModal}
				onClose={() => setShowNewInvoiceModal(false)}
				extraRequestParams={extraRequestParams}
				isCustomer={false}
				companyHasStandAloneEnable={false}
			/>
			<CancelNewGRModal
				open={cancelGRModal || exitGRModal}
				onAccept={() => acceptActionModal()}
				onClose={() => {
					setCancelGRModal(false)
					setExitGRModal(false)
				}}
			/>
			<ErrorModal open={!!errorMessage?.code} errorCode={errorMessage?.message} />
			<RejectPendingMessagePODetail
				open={openPendingMessageModal}
				close={() => setOpenPendingMessageModal(false)}
				onAccept={() => history.push(URLS.PO_LIST)}
			/>
			<FileViewerModal onClose={closePDF} {...fileViewer} />
		</NetcurioGrid>
	)
}
