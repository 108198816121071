import { Comment, getCurrentUser, URLS } from '@netcurio/frontend-common'
import {
	CommentSectionVariant,
	CommentsSection,
	NetcurioButton,
	NetcurioIcons,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import classNames from 'classnames'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthenticatedHeader } from '../../components/AuthenticatedHeader/AuthenticatedHeader'
import { ItemRFCM } from '../../types'
import { InvoiceRfcm, ModalRFCM, NewRFCM, SupplierRfcm } from '../../types/RFCM'
import { ValidationItemRFCM } from '../../types/ValidationItemRFCM'
import { beforeUnloadListener } from '../../utilities/beforeUnloadListener'
import { connection } from '../../utilities/connection'
import { showErrorComponent } from '../../utilities/errorCode'
import { expiredToken } from '../../utilities/expiredToken'
import HeaderInformation from './headerInformation'
import ItemsList from './itemsList'
import { CancelNewRequestForCreditMemoModal } from './Modals/CancelNewRequestForCreditMemoModal/CancelNewRequestForCreditMemoModal'
import { ErrorModal } from './Modals/ErrorModal/ErrorModal'
import { SaveNewRequestForCreditMemoModal } from './Modals/SaveNewRequestForCreditMemoModal/SaveNewRequestForCreditMemoModal'
import styles from './newRequestForCreditMemo.module.scss'
import * as queries from './queries'

export const NewRequestForCreditMemo = () => {
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const history = useHistory()
	const client = React.useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const [newRequestForCreditMemo, setNewRequestForCreditMemo] = useState<NewRFCM>({
		itemDataList: [
			{
				description: '',
				quantity: '',
				netAmount: '',
				taxIndicator: ''
			}
		],
		dataSupplier: {
			name: '',
			rfc: ''
		},
		invoice: {
			uuid: '',
			total: 0,
			currency: '',
			subtotal: 0
		}
	})
	const [validateItem, setValidateItem] = useState<ValidationItemRFCM[]>([
		{
			errorDescription: '',
			errorQuantity: '',
			errorNetAmount: '',
			errorTaxIndicator: '',
			cancelImgDisabled: true
		}
	])
	const [disabledSendRFCMButton, setDisabledSendRFCMButton] = useState<boolean>(true)
	const [disabledRow, setDisabledRow] = useState<boolean>(true)
	const [reasonEmpty, setReasonEmpty] = useState<boolean>(false)
	const [rfcmReason, setRfcmReason] = useState<string>('')
	const [missingFields, setMissingFields] = useState<boolean>(false)
	const [subtotalExceeded, setSubtotalExceeded] = useState<boolean>(false)
	const [commentSend, setCommentSend] = useState<Comment>()
	const [stateModal, setStateModal] = useState<ModalRFCM>({
		cancelNewRFCMModal: false,
		saveRFCMModal: false,
		errorModal: false,
		errorCode: null
	})
	const [serieFolioUUID, setSerieFolioUUID] = useState<string>('')

	//mount
	useEffect(() => {
		beforeUnloadListener('add')
	}, [])

	const addNewComment = (newComment: string): void => {
		setCommentSend({ ...commentSend, text: newComment })
	}

	const cleanInvoice = (): void => {
		setSerieFolioUUID('')
		setNewRequestForCreditMemo({
			itemDataList: [
				{
					description: '',
					quantity: '',
					netAmount: '',
					taxIndicator: ''
				}
			],
			dataSupplier: {
				name: '',
				rfc: ''
			},
			invoice: {
				uuid: '',
				total: 0,
				currency: '',
				subtotal: 0
			}
		})
		setValidateItem([
			{
				errorDescription: '',
				errorQuantity: '',
				errorNetAmount: '',
				errorTaxIndicator: '',
				cancelImgDisabled: true
			}
		])
		setDisabledSendRFCMButton(true)
		setMissingFields(false)
	}

	const cleanItemList = (): void => {
		if (missingFields) {
			setMissingFields(false)
		}
	}

	const resetChangeInvoice = (invoice: InvoiceRfcm | null): void => {
		if (invoice === null) {
			cleanInvoice()
		} else {
			newRequestForCreditMemo.invoice = invoice
			setNewRequestForCreditMemo(newRequestForCreditMemo)
		}
	}

	const setSupplier = (selectedSupplier: SupplierRfcm | null): void => {
		const newRequestForCreditMemoCopy = newRequestForCreditMemo
		if (selectedSupplier !== null) {
			if (newRequestForCreditMemoCopy.dataSupplier.name !== selectedSupplier.name) {
				newRequestForCreditMemoCopy.dataSupplier.name = selectedSupplier.name
				newRequestForCreditMemoCopy.dataSupplier.rfc = selectedSupplier.rfc
			}
		} else {
			newRequestForCreditMemo.dataSupplier.name = undefined
			newRequestForCreditMemo.dataSupplier.rfc = undefined
			cleanItemList()
		}
		setNewRequestForCreditMemo(newRequestForCreditMemo)
	}

	useEffect(() => {
		const activeSendButton = (): void => {
			const firstItem = newRequestForCreditMemo.itemDataList[0]
			if (
				disabledSendRFCMButton &&
				newRequestForCreditMemo.invoice.uuid !== '' &&
				firstItem.description !== '' &&
				firstItem.netAmount !== '' &&
				firstItem.quantity !== '' &&
				firstItem.taxIndicator !== '' &&
				rfcmReason !== '' &&
				!subtotalExceeded
			) {
				setDisabledSendRFCMButton(false)
			} else if (
				!disabledSendRFCMButton &&
				(newRequestForCreditMemo.invoice.uuid === '' ||
					firstItem.description === '' ||
					firstItem.netAmount === '' ||
					firstItem.quantity === '' ||
					firstItem.taxIndicator === '' ||
					rfcmReason === '' ||
					subtotalExceeded)
			) {
				setDisabledSendRFCMButton(true)
			}
		}
		activeSendButton()
	}, [newRequestForCreditMemo, rfcmReason])

	const callCancelNewRFC = (): void => {
		setStateModal({ ...stateModal, cancelNewRFCMModal: true })
	}

	const addItem = (): void => {
		const items = newRequestForCreditMemo.itemDataList
		const item = {
			description: '',
			quantity: '',
			netAmount: '',
			taxIndicator: ''
		}
		const tempValidateItem = {
			cancelImgDisabled: true,
			errorDescription: '',
			errorQuantity: '',
			errorNetAmount: '',
			errorTaxIndicator: ''
		}

		if (items.length < 1 || (checkMissingFields() && !subtotalExceeded)) {
			items.push(item)
			validateItem.push(tempValidateItem)
		}
		setNewRequestForCreditMemo({
			...newRequestForCreditMemo,
			itemDataList: items
		})
		validateSubtotal(items, validateItem)
	}

	const checkMissingFields = (): boolean => {
		const items = newRequestForCreditMemo.itemDataList
		const tempValidateItem = validateItem
		let missingFiled = false
		items.forEach((item: ItemRFCM, index: number) => {
			if (item.description === '') {
				tempValidateItem[index] = {
					...tempValidateItem[index],
					errorDescription: 'setRequiredData'
				}
				if (!missingFiled) {
					missingFiled = true
				}
			} else {
				tempValidateItem[index] = {
					...tempValidateItem[index],
					errorDescription: ''
				}
			}
			if (item.quantity === '') {
				tempValidateItem[index] = {
					...tempValidateItem[index],
					errorQuantity: 'setRequiredData'
				}
				if (!missingFiled) {
					missingFiled = true
				}
			} else {
				tempValidateItem[index] = {
					...tempValidateItem[index],
					errorQuantity: ''
				}
			}
			if (item.netAmount === '') {
				tempValidateItem[index] = {
					...tempValidateItem[index],
					errorNetAmount: 'setRequiredData'
				}
				if (!missingFiled) {
					missingFiled = true
				}
			} else {
				tempValidateItem[index] = {
					...tempValidateItem[index],
					errorNetAmount: ''
				}
			}
			if (item.taxIndicator === '') {
				tempValidateItem[index] = {
					...tempValidateItem[index],
					errorTaxIndicator: 'setRequiredData'
				}
				if (!missingFiled) {
					missingFiled = true
				}
			} else {
				tempValidateItem[index] = {
					...tempValidateItem[index],
					errorTaxIndicator: ''
				}
			}
		})
		setValidateItem(tempValidateItem)
		if (missingFiled) {
			setMissingFields(true)
			return false
		} else {
			setMissingFields(false)
			return true
		}
	}

	const validateSubtotal = (items: ItemRFCM[], validateItems: ValidationItemRFCM[]) => {
		const subtotal: number = items.reduce((sum, item) => sum + parseFloat(item.netAmount), 0)
		if (subtotal > newRequestForCreditMemo.invoice.subtotal) {
			validateItems.forEach((v) => {
				v.errorNetAmount = 'exceeded'
			})
			setSubtotalExceeded(true)
		} else {
			validateItems.forEach((v, index) => {
				if (items[index].netAmount !== '') {
					v.errorNetAmount = ''
				}
			})
			setSubtotalExceeded(false)
		}
	}

	const handleItemFieldChange = (value: string, index: number, nameField: string): void => {
		const itemData = newRequestForCreditMemo.itemDataList
		const tempValidateItem = validateItem
		if (nameField === 'description') {
			itemData[index] = {
				...itemData[index],
				description: value
			}
			tempValidateItem[index] = {
				...tempValidateItem[index],
				errorDescription: ''
			}
		}
		if (nameField === 'quantity') {
			itemData[index] = {
				...itemData[index],
				quantity: value
			}
			tempValidateItem[index] = {
				...tempValidateItem[index],
				errorQuantity: ''
			}
		}
		if (nameField === 'netAmount') {
			itemData[index] = {
				...itemData[index],
				netAmount: value
			}
			tempValidateItem[index] = {
				...tempValidateItem[index],
				errorNetAmount: ''
			}
		}
		if (nameField === 'taxIndicator') {
			itemData[index] = {
				...itemData[index],
				taxIndicator: value
			}
			tempValidateItem[index] = {
				...tempValidateItem[index],
				errorTaxIndicator: ''
			}
		}

		validateSubtotal(itemData, tempValidateItem)

		changeCancelImgItem(itemData[index], index)
		setValidateItem(tempValidateItem)
		setNewRequestForCreditMemo({
			...newRequestForCreditMemo,
			itemDataList: itemData
		})
	}

	const deleteItem = (itemIndex: number): void => {
		const items = newRequestForCreditMemo.itemDataList
		if (items.length === 1) {
			setSubtotalExceeded(false)
			setValidateItem([
				{
					cancelImgDisabled: true,
					errorDescription: '',
					errorQuantity: '',
					errorNetAmount: '',
					errorTaxIndicator: ''
				}
			])
			setNewRequestForCreditMemo({
				...newRequestForCreditMemo,
				itemDataList: [
					{
						description: '',
						quantity: '',
						netAmount: '',
						taxIndicator: ''
					}
				]
			})
		} else {
			setMissingFields(false)
			const newItems = items.filter((_, i) => i !== itemIndex)
			const newValidateItem = validateItem.filter((_, i) => i !== itemIndex)
			setValidateItem(newValidateItem)
			setNewRequestForCreditMemo({
				...newRequestForCreditMemo,
				itemDataList: newItems
			})
			validateSubtotal(newItems, newValidateItem)
		}
	}

	const changeCancelImgItem = (item: ItemRFCM, index: number): void => {
		if (
			item.description !== '' ||
			item.netAmount !== '' ||
			item.quantity !== '' ||
			item.taxIndicator !== ''
		) {
			const tempValidate = validateItem
			tempValidate[index] = {
				...tempValidate[index],
				cancelImgDisabled: false
			}
			setValidateItem(tempValidate)
			if (missingFields) {
				setMissingFields(false)
			}
		}
	}

	const rfcmReasonChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		if (reasonEmpty && e.target.value !== '') {
			setReasonEmpty(false)
		}
		setRfcmReason(e.target.value)
	}

	const resetRfcmReasonChange = (value: string): void => {
		setRfcmReason(value)
	}

	const checkRFCMReasonField = (): boolean => {
		if (rfcmReason) {
			setReasonEmpty(false)
			return true
		} else {
			setReasonEmpty(true)
			return false
		}
	}

	const sendRFCM = (): void => {
		if (checkMissingFields() && checkRFCMReasonField() && !subtotalExceeded) {
			setStateModal({ ...stateModal, saveRFCMModal: true })
		}
	}

	const createNewRFCM = (): void => {
		const currentUser = getCurrentUser()
		showLoadingSpinner()
		const newRequestForCreditMemoData = { ...newRequestForCreditMemo }
		const rfcmItems = newRequestForCreditMemoData.itemDataList.map((item, index) => {
			return {
				position: index + 1,
				concept_description: item.description,
				amount: parseFloat(item.quantity),
				net_amount: parseFloat(item.netAmount),
				tax: parseFloat(item.taxIndicator)
			}
		})
		const rfcmData = {
			supplier: newRequestForCreditMemoData.dataSupplier.rfc,
			request_reason: rfcmReason,
			invoice: newRequestForCreditMemo.invoice.uuid,
			items: rfcmItems,
			comment: commentSend?.text ? commentSend : undefined
		}
		client
			.mutate({
				mutation: queries.CREATE_REQUEST_FOR_CREDIT_MEMO,
				variables: rfcmData
			})
			.then((result) => {
				const rfcmId = result.data.createRequestForCreditMemo.id
				history.push(
					URLS.RFCM_DETAIL +
						'?requestforcreditmemo=' +
						rfcmId +
						'&customer=' +
						currentUser.company.rfc
				)
				hideLoadingSpinner()
			})
			.catch((error) => {
				console.error(error)
				const errorCode = showErrorComponent(error)
				if (!expiredToken(errorCode)) {
					setStateModal({
						...stateModal,
						errorModal: true,
						errorCode: errorCode
					})
				}
				hideLoadingSpinner()
			})
	}
	const redirectToRequestForCreditMemoList = () => {
		history.push(URLS.REQUEST_FOR_CREDIT_MEMO_LIST)
	}

	return (
		<div className={styles.container}>
			<AuthenticatedHeader>
				<div>
					<NetcurioButton
						variant="outlined"
						color="error"
						size={'small'}
						onClick={callCancelNewRFC}
						endIcon={<NetcurioIcons.ArrowBack className={classNames(styles.icon)} />}
					>
						<span> {t('comebackListText')} </span>
					</NetcurioButton>
				</div>
				<div></div>
			</AuthenticatedHeader>
			<div className={styles.containerTotal}>
				<HeaderInformation
					cleanInvoice={cleanInvoice}
					resetChangeInvoice={resetChangeInvoice}
					setSupplier={setSupplier}
					invoiceProps={newRequestForCreditMemo.invoice}
					setDisabledRow={setDisabledRow}
					reasonEmpty={reasonEmpty}
					rfcmReason={rfcmReason}
					rfcmReasonChange={rfcmReasonChange}
					resetRfcmReasonChange={resetRfcmReasonChange}
					serieFolioUUID={serieFolioUUID}
					setSerieFolioUUID={setSerieFolioUUID}
				/>
				<div className={styles.separatorGrayBlock}></div>
				<div className={styles.mainModule}>
					<div className={styles.listContainer}>
						<ItemsList
							missingFields={missingFields}
							subtotalExceeded={subtotalExceeded}
							itemDataList={newRequestForCreditMemo.itemDataList}
							addItem={addItem}
							handleItemFieldChange={handleItemFieldChange}
							deleteItem={deleteItem}
							validateItem={validateItem}
							disabledRow={disabledRow}
						/>
					</div>
					<div className={styles.moduleLateral}>
						<div className={styles.commentsSectionContainer}>
							<CommentsSection
								variant={CommentSectionVariant.Simple}
								onChange={(e) => addNewComment(e)}
							/>
							<div className={styles.buttonMakeRFCMContainer}>
								<NetcurioButton
									variant="outlined"
									color="secondary"
									disabled={disabledSendRFCMButton}
									onClick={() => sendRFCM()}
								>
									{t('sendRequestText')}
								</NetcurioButton>
							</div>
						</div>
					</div>
				</div>
			</div>
			<CancelNewRequestForCreditMemoModal
				openModal={stateModal.cancelNewRFCMModal}
				onCloseModal={() => setStateModal({ ...stateModal, cancelNewRFCMModal: false })}
				cancel={() => redirectToRequestForCreditMemoList()}
			/>
			<SaveNewRequestForCreditMemoModal
				open={stateModal.saveRFCMModal}
				onClose={() => setStateModal({ ...stateModal, saveRFCMModal: false })}
				onAccept={() => createNewRFCM()}
			/>
			<ErrorModal open={!!stateModal?.errorCode} errorCode={stateModal?.errorCode} />
		</div>
	)
}
