
import { NetcurioAlert, Severity } from '../NetcurioAlert/NetcurioAlert'
import { NetcurioCollapse } from '../NetcurioCollapse/NetcurioCollapse'
import styles from './InformationMessage.module.scss'

interface InformationMessageProps {
	open: boolean
	messageType: Severity
	message: string
}

/**
 * InformationMessage
 * @param open <boolean>: If `true`, the component is shown.
 * @param messageType <'error' | 'info'>: Component styles depends on this type.
 * @param message <string>: Text string to render in the component.
 * @returns component
 */

export const InformationMessage = ({ open, messageType, message }: InformationMessageProps) => {
	return (
		<NetcurioCollapse in={open}>
			<NetcurioAlert
				severity={messageType}
				messagePadding="0"
			>
				<span className={styles.textContainer}>{message}</span>
			</NetcurioAlert>
		</NetcurioCollapse>
	)
}
