import { Company } from '@netcurio/frontend-common'
import { useNetcurioLoader } from '@netcurio/frontend-components'
import { useEffect, useState } from 'react'
import { useClient } from '../../../../hooks/useClient'
import * as queries from './queries'

export const useCompany = ({
	rfc,
	onLoadCompanyCallback
}: {
	rfc: string
	onLoadCompanyCallback?: (company: Company) => void
}) => {
	const client = useClient()
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const [company, setCompany] = useState<Company | null>(null)

	const patchCompany = async (fieldsToUpdate: Partial<Company>) => {
		return client.mutate({
			mutation: queries.PatchCompany,
			variables: {
				rfc,
				...fieldsToUpdate
			}
		})
	}

	useEffect(() => {
		showLoadingSpinner()
		client
			.query({
				query: queries.Company,
				variables: {
					rfc: rfc
				}
			})
			.then((result) => {
				setCompany(result.data.Company)
				onLoadCompanyCallback?.(result.data.Company)
			})
			.finally(() => hideLoadingSpinner())
	}, [])

	return { company, setCompany, patchCompany }
}
