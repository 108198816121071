import gql from 'graphql-tag'
export const UPDATE_INVOICES_TO_CANCELLED = gql`
	mutation updateInvoicesToCanceled($invoicesIds: [String!]!, $cancellation_reason: String!) {
		updateInvoicesToCanceled(invoicesIds: $invoicesIds, cancellation_reason: $cancellation_reason) {
			code
			message
			counter
		}
	}
`
