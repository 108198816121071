export default {
	UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
	NO_DATA_FOUND: 'NO_DATA_FOUND',
	INVALID_TOKEN: 'INVALID_TOKEN',
	ERROR: 'ERROR',
	EXCEPTION: 'EXCEPTION',
	NETWORK_ERROR: 'NETWORK_ERROR',
	AWS_NOT_AUTHENTICATED_ERROR: 'not authenticated',
	UNSUPPORTED_BEHAVIOR: 'UNSUPPORTED_BEHAVIOR'
}
