import { Filter, Roles, URLS } from '@netcurio/frontend-common'
import { useNetcurioLoader } from '@netcurio/frontend-components'
import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import { Dayjs } from 'dayjs'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthenticatedHeader } from '../../components/AuthenticatedHeader/AuthenticatedHeader'
import { AddButton } from '../../components/HeaderButtons/AddButton'
import { ExportButton } from '../../components/HeaderButtons/ExportButton'
import { connection } from '../../utilities/connection'
import Constants from '../../utilities/constants'
import { showErrorComponent } from '../../utilities/errorCode'
import { expiredToken } from '../../utilities/expiredToken'
import { downloadExcelFile } from '../../utilities/file-handling/download-excel-file'
import { FileDescriptor } from '../../utilities/file-handling/file-descriptor'
import listHelper from '../../utilities/listHelper'
import UserInformation from '../../utilities/userInformation'
import { CatchedErrorModalRFQList } from './Modals/CatchedErrorModalRFQList'
import * as queries from './queries'
import { RequestForQuotationTable } from './requestForQuotationTable'
import { objectStatusFilterArray } from './tableParameterArray'
import { DataRFQ, FilterToApply, RFQ, RfqList, UpdateFilter } from './types'

export const RequestForQuotationList = (): ReactElement => {
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const [userRole, setUserRole] = useState<Roles>()
	const history = useHistory<string>()
	const initialSort = 'created_at'
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const [showBarLoader, setShowBarLoader] = useState<boolean>()
	const [errorCode, setErrorCode] = useState<string>()
	const [listRfq, setListRfq] = useState<RfqList>({
		rowsActual: undefined,
		totalRows: undefined,
		filterContainerBar: false,
		dataFiltersArray: [],
		dataFilters: {},
		deleteRange: false,
		filtersOfTypeStatus: {
			status: {
				list: objectStatusFilterArray(),
				numSelectOptions: 4,
				dataFilter: ''
			}
		},
		limitRows: undefined,
		fieldList: initialSort,
		orderList: Constants.ORDER.DESC,
		actualFilterData: undefined,
		dataRfq: {}
	})
	const [activePagination, setActivePagination] = useState<boolean>(false)
	const [stopPagination, setStopPagination] = useState<boolean>(true)

	useEffect(() => {
		const isMediumWindow = 600
		const containerHeightCompensation = 95
		const containerHeightSmall = 500
		const heightRow = 45
		const additionalRow = 2

		let finalHeight: number
		if (window.innerHeight > isMediumWindow) {
			finalHeight = window.innerHeight - containerHeightCompensation
		} else {
			finalHeight = containerHeightSmall
		}
		const numberRows: number = Math.round(finalHeight / heightRow) + additionalRow
		setUserRole(UserInformation.getCompanyRole())
		setListRfq((prevState) => ({
			...prevState,
			limitRows: numberRows,
			rowsActual: numberRows
		}))
		showLoadingSpinner()
	}, [])

	useEffect(() => {
		if (listRfq.limitRows !== undefined) {
			filteringRFQ(0, initialSort, '', initialSort)
		}
	}, [listRfq.limitRows])

	useEffect(() => {
		if (activePagination && stopPagination) {
			setActivePagination(false)
			setStopPagination(false)
			filteringRFQ(listRfq.rowsActual, '', '', '', undefined, undefined, undefined, 'none')
		}
	}, [activePagination, stopPagination])

	const actionHeaderButton = (nameButton: string) => {
		switch (nameButton) {
			case 'exportList':
				downloadReportExcel()
				break
			case 'newRFQ':
				history.push(URLS.RFQ_NEW)
				break
			default:
				break
		}
	}

	const downloadReportExcel = () => {
		if (Object.keys(listRfq.dataRfq).length > 0) {
			showLoadingSpinner()
			const copyFilterToApply: Array<Filter> = JSON.parse(JSON.stringify(listRfq.dataFiltersArray))
			copyFilterToApply.forEach(listHelper.applyTimeZoneDate)
			const body = JSON.stringify({
				filters: copyFilterToApply,
				sort_field: listRfq.fieldList,
				sort_order: listRfq.orderList
			})

			listHelper
				.generateReportList(body, 'requests-for-quotation')
				.then(async (res) => {
					if (res.ok) {
						res.json().then((responseJson: FileDescriptor) => {
							downloadExcelFile(responseJson)
						})
						hideLoadingSpinner()
					} else {
						const error: Error = await res.json()
						handleError(error)
					}
				})
				.catch(handleError)
		}
	}

	const filteringRFQ = (
		skip: number,
		sortField: string,
		sortOrder: string,
		elementFilterActual: string,
		valueFilter?: string,
		initRange?: number | Dayjs,
		finalRange?: number | Dayjs,
		filterRemove?: string
	) => {
		let fieldListData: string = listRfq.fieldList
		let orderListData: string = listRfq.orderList
		let columnFilterActual: string = listRfq.actualFilterData
		let currentList: DataRFQ = { ...listRfq.dataRfq }

		if (sortOrder !== '') {
			orderListData = sortOrder
			setListRfq((prevState) => ({
				...prevState,
				orderList: sortOrder
			}))
		}
		if (sortField) {
			setListRfq((prevState) => ({
				...prevState,
				fieldList: sortField
			}))
			fieldListData = sortField
		}
		if (elementFilterActual !== '') {
			columnFilterActual = elementFilterActual
			setListRfq((prevState) => ({
				...prevState,
				actualFilterData: elementFilterActual
			}))
		}
		let typeFilterActual: string
		let initRangeActual: number | Dayjs
		let finalRangeActual: number | Dayjs
		const valueFilterActual: string = valueFilter
		switch (columnFilterActual) {
			case 'id':
			case 'branch_office':
			case 'customer':
			case 'supplier':
			case 'customer_reference':
			case 'created_by':
				typeFilterActual = 'wildcard'
				break
			case 'status':
				typeFilterActual = 'exact_match'
				break
			case 'created_at':
				typeFilterActual = 'date'
				initRangeActual = initRange
				finalRangeActual = finalRange
				break
		}

		if (skip > 0) {
			const rowsActual: number = listRfq.rowsActual + listRfq.limitRows
			setListRfq((prevState) => ({
				...prevState,
				rowsActual: rowsActual
			}))
		} else {
			setListRfq((prevState) => ({
				...prevState,
				rowsActual: prevState.limitRows,
				dataRfq: {}
			}))
			currentList = {}
		}
		const filterToApply: Array<Filter> = activeFilterToApply(
			typeFilterActual,
			valueFilterActual,
			initRangeActual,
			finalRangeActual,
			columnFilterActual,
			filterRemove,
			sortField
		)
		queryRfq(fieldListData, orderListData, filterToApply, skip, currentList)
	}

	const activeFilterToApply = (
		typeFilterActual: string,
		valueFilterActual: string,
		initRangeActual: number | Dayjs,
		finalRangeActual: number | Dayjs,
		columnFilterActual: string,
		filterRemove: string,
		sortField: string
	): Array<Filter> => {
		const resultFilterToApply = listHelper.generateFiltersToApply(
			typeFilterActual,
			valueFilterActual,
			initRangeActual,
			finalRangeActual,
			columnFilterActual,
			filterRemove,
			sortField,
			listRfq.dataFilters,
			listRfq.filtersOfTypeStatus
		) as FilterToApply

		const objUpdate: UpdateFilter = resultFilterToApply.objectForStateUpdate

		setListRfq((prevState) => ({
			...prevState,
			dataFilters: objUpdate.dataFilters,
			dataFiltersArray: objUpdate.dataFiltersArray,
			deleteRange: objUpdate.deleteRange,
			filterContainerBar: objUpdate.filterContainerBar
		}))
		if (objUpdate.filtersOfTypeStatus)
			setListRfq((prevState) => ({
				...prevState,
				filtersOfTypeStatus: objUpdate.filtersOfTypeStatus
			}))
		return resultFilterToApply.filterToApply
	}

	const queryRfq = (
		fieldListData: string,
		orderListData: string,
		filterToApply: Array<Filter>,
		skip: number,
		currentList: DataRFQ
	) => {
		const copyFilterToApply: Array<Filter> = JSON.parse(JSON.stringify(filterToApply))
		copyFilterToApply.forEach(listHelper.applyTimeZoneDate)
		client
			.query({
				query:
					userRole === Roles.CUSTOMER
						? queries.REQUEST_FOR_QUOTATIONS_CUSTOMER
						: queries.REQUEST_FOR_QUOTATIONS_SUPPLIER,
				variables: {
					limit: listRfq.limitRows,
					skip,
					sort_field: fieldListData,
					sort_order: orderListData,
					filter: copyFilterToApply
				}
			})
			.then((result) => {
				let interCount: number = skip
				const data: DataRFQ = { ...currentList }
				const obj: Array<RFQ> = result.data.RequestForQuotations.list
				for (const keyName in obj) {
					interCount++
					data[interCount] = obj[keyName]
				}
				setListRfq((prevState) => ({
					...prevState,
					totalRows: result.data.RequestForQuotations.total,
					dataRfq: data,
					filtersOfTypeStatus: {
						...prevState.filtersOfTypeStatus,
						status: {
							...prevState.filtersOfTypeStatus.status,
							dataFilter: prevState.filtersOfTypeStatus.status.dataFilter || ''
						}
					}
				}))
				setStopPagination(true)
				hideLoadingSpinner()
				setShowBarLoader(false)
			})
			.catch(handleError)
	}

	const handleError = (error: Error) => {
		const errorCode: string = showErrorComponent(error)
		if (!expiredToken(errorCode)) {
			setListRfq((prevState) => ({
				...prevState,
				filtersOfTypeStatus: {
					...prevState.filtersOfTypeStatus,
					status: {
						...prevState.filtersOfTypeStatus.status,
						dataFilter: ''
					}
				},
				dataRfq: {}
			}))
			setStopPagination(true)
			setErrorCode(errorCode)
		}
		hideLoadingSpinner()
		setShowBarLoader(false)
	}

	const closeFilterContainerBar = () => {
		listHelper.closeFilterContainerBar(filteringRFQ, initialSort)
		setListRfq((prevState) => ({
			...prevState,
			deleteRange: true
		}))
	}

	const deleteFilterActive = (indexObject: string) => {
		listHelper.deleteFilterActive(indexObject, listRfq.dataFilters, closeFilterContainerBar, filteringRFQ)
		setListRfq((prevState) => ({
			...prevState,
			deleteRange: true
		}))
	}

	return (
		<>
			<AuthenticatedHeader>
				<div>
					{userRole === Roles.CUSTOMER && (
						<AddButton onClick={() => actionHeaderButton('newRFQ')} translationKey="newRFQ" />
					)}
				</div>
				<div>
					<ExportButton onClick={() => actionHeaderButton('exportList')} />
				</div>
			</AuthenticatedHeader>
			<RequestForQuotationTable
				dataRFQ={listRfq.dataRfq}
				filteringRFQ={filteringRFQ}
				rowsActual={listRfq.rowsActual}
				totalRows={listRfq.totalRows}
				filterContainerBar={listRfq.filterContainerBar}
				closeFilterContainerBar={closeFilterContainerBar}
				dataFiltersArray={listRfq.dataFiltersArray}
				deleteFilterActive={deleteFilterActive}
				deleteRange={listRfq.deleteRange}
				userRole={userRole}
				filtersOfTypeStatus={listRfq.filtersOfTypeStatus}
				setActivePagination={setActivePagination}
				showBarLoader={showBarLoader}
				setShowBarLoader={setShowBarLoader}
			/>
			<CatchedErrorModalRFQList open={!!errorCode} errorCode={errorCode} />
		</>
	)
}
