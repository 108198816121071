import {
	dateFormatter,
	getSerieFolio,
	parseTimestampToTimeZone,
	PURCHASE,
	Roles,
	URLS
} from '@netcurio/frontend-common'
import { NetcurioCheckbox, NetcurioChip, NetcurioIcons, NetcurioTooltip } from '@netcurio/frontend-components'
import classNames from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Constants from '../../../utilities/constants'
import Formatter from '../../../utilities/formatter'
import styles from './invoicesList.module.scss'
import { TableRowProps } from './types'

export const InvoicesTableRow = ({
	informationTable: {
		uuid,
		serie,
		folio,
		status,
		total,
		payment_complements,
		proposed_payment_date,
		reference_type,
		consumption,
		purchase_order,
		sender,
		receiver,
		reference,
		pinned,
		advance,
		override_by,
		currency
	},
	userRole,
	uuidInvoices,
	setUuidInvoices
}: TableRowProps) => {
	const { t } = useTranslation()
	const [checked, setChecked] = useState<boolean>(false)
	const isCustomer: boolean = userRole === Roles.CUSTOMER
	const isConsumptionRelationship: boolean = reference_type === Constants.INVOICE.CONSUMPTION
	const idRelated: string = isConsumptionRelationship ? consumption?.id : purchase_order?.id
	const customerReference: string = isConsumptionRelationship
		? consumption?.customer_reference
		: purchase_order?.customer_reference
	const isSupplier = userRole === Roles.SUPPLIER
	const isMissingLink = status.key === Constants.LINKS.MISSING_LINK
	const hasStatusError = status.key === Constants.ERROR.ERROR

	const addInvoiceToArchive = () => {
		const tempSet: Set<string> = new Set(uuidInvoices)
		tempSet.add(uuid)
		setUuidInvoices(tempSet)
	}
	const deleteInvoiceToArchive = () => {
		const tempSetArchived: Set<string> = new Set(uuidInvoices)
		tempSetArchived.delete(uuid)
		setUuidInvoices(tempSetArchived)
	}

	const goTo = () => {
		if (isSupplier && (isMissingLink || hasStatusError)) {
			return `${URLS.INVOICE_EDIT}?invoice=${uuid}`
		} else {
			return `${URLS.INVOICE_DETAIL}?invoice=${uuid}`
		}
	}

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked)
		if (event.target.checked) addInvoiceToArchive()
		else deleteInvoiceToArchive()
	}

	const getStatusPill = () => {
		return <NetcurioChip label={status.value} severity={'success'} />
	}

	return (
		<div
			className={classNames(
				styles.containerRowList,
				checked ? styles.containerInvoicesCheckedArchived : ''
			)}
		>
			<div className={classNames(styles.fieldInvoices, styles.tableFieldCheckBoxContainer)}>
				<NetcurioCheckbox
					key={uuid}
					size="small"
					checked={checked}
					onChange={handleChange}
					inputProps={{ 'aria-label': 'controlled' }}
					className={classNames(checked ? styles.checked : '')}
				/>
				{pinned && (
					<div className={styles.pinIconContainer}>
						<NetcurioIcons.PushPin className={styles.pinIcon} />
					</div>
				)}
			</div>
			<Link to={goTo()} className={styles.gridAreaColumnRow}>
				<p className={styles.fieldInvoices}>{getSerieFolio(serie, folio)}</p>
				<p className={styles.fieldInvoices}>{getStatusPill()}</p>
				<p className={styles.fieldInvoices}>{'-'}</p>
				<p className={styles.fieldInvoices}>{isCustomer ? sender?.name : receiver?.name}</p>
				<div className={styles.fieldInvoices}>
					{proposed_payment_date === null || proposed_payment_date === undefined ? (
						<p>{t(PURCHASE.PENDING)}</p>
					) : (
						dateFormatter.format(parseTimestampToTimeZone(proposed_payment_date))
					)}
				</div>
				<div className={styles.fieldInvoices}>
					{reference === null ? (
						<p>{t(PURCHASE.PENDING)}</p>
					) : (
						Formatter.getInvoiceIdRelated(idRelated, customerReference)
					)}
				</div>
				<div className={styles.fieldInvoices}>
					<div className={styles.serieFolioComplementInvoices}>
						<p>{payment_complements.length > 0 ? t('invoices.true') : t('invoices.false')}</p>
					</div>
				</div>
				<p className={styles.fieldInvoices}>{Formatter.currency.format(total)}</p>
				<p className={styles.fieldInvoices}>{currency}</p>
			</Link>
			<div className={styles.containerManualAuthorization}>
				{override_by ? (
					<NetcurioTooltip title={t('manuallyAuthorized')} disableInteractive>
						<NetcurioIcons.InfoOutlined className={styles.manualAuthorizationIcon} />
					</NetcurioTooltip>
				) : (
					<div className={styles.filler}></div>
				)}
				{advance ? (
					<NetcurioTooltip title={t('advance')} placement={'left'} disableInteractive>
						<NetcurioIcons.LocalOfferOutlined className={styles.manualAuthorizationIcon} />
					</NetcurioTooltip>
				) : (
					<div className={styles.filler}></div>
				)}
			</div>
		</div>
	)
}
