import { useNetcurioLoader } from '@netcurio/frontend-components'
import { ApolloQueryResult } from 'apollo-boost'
import { useCallback, useEffect, useState } from 'react'
import { useClient } from '../../hooks/useClient'
import { CreditMemo, CreditMemoItem, RequestForCreditMemoItem } from '../../types'
import constants from '../../utilities/constants'
import { showErrorComponent } from '../../utilities/errorCode'
import { expiredToken } from '../../utilities/expiredToken'
import { getUrlParameter } from '../../utilities/getUrlParameter'
import { CREDIT_MEMO_ASSOCIATION, CREDIT_MEMO_DETAIL, SEND_CREDIT_MEMO_RELATED_TO_ADVANCE } from './queries'
import { CreditMemoAssociationItem, ModalTypes, WorkflowValueEnum } from './types'

export const useCreditMemoDetail = (workflow: WorkflowValueEnum) => {
	const client = useClient()
	const [errorMessage, setErrorMessage] = useState<string>()
	const [creditMemoDetail, setCreditMemoDetail] = useState<CreditMemo>()
	const [rfcmReference, setRfcmReference] = useState<string>()
	const [errorCode, setErrorCode] = useState<string>()
	const [cmHasBeenSent, setCmHasBeenSent] = useState<boolean>(false)
	const creditMemoId = getUrlParameter('creditmemo')
	const [currentModal, setCurrentModal] = useState<ModalTypes | null>(null)
	const [rfcmItems, setRfcmItems] = useState<RequestForCreditMemoItem[]>([])

	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()

	const formatErrorMessage = (message: string) => {
		return message.split(':')[1]
	}

	const handleError = useCallback((error: Error) => {
		const newErrorCode = showErrorComponent(error)
		if (!expiredToken(newErrorCode)) {
			setErrorCode(newErrorCode)
			if (error.message) setErrorMessage(formatErrorMessage(error.message))
			setCurrentModal(ModalTypes.Error)
		}
	}, [])

	const fetchCreditMemoDetail = () => {
		showLoadingSpinner()
		client
			.query({
				query: CREDIT_MEMO_DETAIL,
				variables: {
					uuid: creditMemoId
				},
				fetchPolicy: 'no-cache'
			})
			.then((result: ApolloQueryResult<{ CreditMemo: CreditMemo }>) => {
				if (result.data.CreditMemo) {
					const creditMemo = result.data.CreditMemo
					setCreditMemoDetail(creditMemo)
					if (creditMemo.reference) {
						setRfcmReference(result.data.CreditMemo.reference)
					}

					if (creditMemo?.request_for_credit_memo && creditMemo?.request_for_credit_memo?.id) {
						setRfcmReference(creditMemo?.request_for_credit_memo?.id)
						setRfcmItems(creditMemo?.request_for_credit_memo?.items ?? [])
					}
				} else {
					const errorCode = showErrorComponent(undefined)
					setErrorCode(errorCode)
					setCurrentModal(ModalTypes.Error)
				}
			})
			.catch(handleError)
			.finally(() => {
				hideLoadingSpinner()
			})
	}

	const saveAssociatedItems = () => {
		if (!creditMemoDetail) return

		showLoadingSpinner()

		const items: Array<CreditMemoAssociationItem> = creditMemoDetail.items.map((cmItem) => {
			return {
				position: cmItem.position,
				reference_position: cmItem.reference_position
			}
		})

		const dataCreditMemo = {
			uuid: creditMemoDetail.uuid,
			RFCMId: rfcmReference,
			items: items
		}
		client
			.mutate({
				mutation: CREDIT_MEMO_ASSOCIATION,
				variables: dataCreditMemo
			})
			.then(() => {
				const isCMSent = [
					constants.CREDIT_MEMO_STATUS.ERROR,
					constants.CREDIT_MEMO_STATUS.MISSING_LINK
				].includes(creditMemoDetail?.status?.key ?? '')
				setCmHasBeenSent(isCMSent)
			})
			.catch(handleError)
			.finally(() => {
				fetchCreditMemoDetail()
				setCurrentModal(null)
				hideLoadingSpinner()
			})
	}

	const sendCreditMemoRelatedToAdvance = () => {
		showLoadingSpinner()
		client
			.mutate({
				mutation: SEND_CREDIT_MEMO_RELATED_TO_ADVANCE,
				variables: {
					uuid: creditMemoDetail!.uuid
				}
			})
			.then(() => {
				fetchCreditMemoDetail()
			})
			.catch(handleError)
			.finally(() => {
				setCurrentModal(null)
				hideLoadingSpinner()
			})
	}

	const updateItems = (items: CreditMemoItem[]) => {
		if (creditMemoDetail) {
			setCreditMemoDetail({
				...creditMemoDetail,
				items: items
			})
		}
	}

	const handleSendCreditMemo = () => {
		if (workflow === WorkflowValueEnum.AdvanceDiscount) {
			sendCreditMemoRelatedToAdvance()
		} else {
			saveAssociatedItems()
		}
	}

	useEffect(() => {
		fetchCreditMemoDetail()
	}, [])

	return {
		errorMessage,
		creditMemoDetail,
		creditMemoId,
		currentModal,
		setCurrentModal,
		fetchCreditMemoDetail,
		handleError,
		errorCode,
		cmHasBeenSent,
		updateItems,
		setErrorCode,
		handleSendCreditMemo,
		rfcmReference,
		setRfcmReference,
		rfcmItems,
		setRfcmItems
	}
}
