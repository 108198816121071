import { Chip } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import {
	MdRefPalette13ChipError,
	MdRefPalette13ChipInfo,
	MdRefPalette13ChipPrimary,
	MdRefPalette13ChipSecondary,
	MdRefPalette13ChipSuccess,
	MdRefPalette13ChipWarning,
	MdRefPalette01PrimaryBDark,
	MdRefPalette02SecondaryAMain,
	NetcurioRefErrorAMain,
	NetcurioRefSuccessBDark,
	NetcurioRefWarningAMain
} from '@netcurio/frontend-design-tokens'

interface NetcurioCustomChipProps {
	label: string
	bgColor?: string
	fontColor?: string
}
/**
 * NetcurioCustomChip
 * @param label <string>: The label to display inside the chip
 * @param bgColor <string>: A color variable used as backgroundColor
 * @param fontColor <string>: A color variable used as color
 @returns component
 */
export const NetcurioCustomChip = ({ label, bgColor, fontColor }: NetcurioCustomChipProps) => {
	return <Chip label={label} sx={{ bgcolor: bgColor, color: fontColor }} />
}

const theme = createTheme({
	components: {
		MuiChip: {
			styleOverrides: {
				colorPrimary: {
					backgroundColor: MdRefPalette13ChipPrimary,
					color: MdRefPalette01PrimaryBDark
				},
				colorSecondary: {
					backgroundColor: MdRefPalette13ChipSecondary,
					color: MdRefPalette02SecondaryAMain
				},
				colorSuccess: {
					backgroundColor: MdRefPalette13ChipSuccess,
					color: NetcurioRefSuccessBDark
				},
				colorWarning: {
					backgroundColor: MdRefPalette13ChipWarning,
					color: NetcurioRefWarningAMain
				},
				colorError: {
					backgroundColor: MdRefPalette13ChipError,
					color: NetcurioRefErrorAMain
				},
				colorInfo: {
					backgroundColor: MdRefPalette13ChipInfo
				}
			}
		}
	}
})

interface NetcurioChipProps {
	label: string
	severity?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined
}
/**
 * NetcurioChip
 * @param label <string>: The label to display inside the chip
 * @param severity <'Success' | 'Info' | 'Warning' | 'Error'>: The variant to use.
 @returns component
 */
export const NetcurioChip = ({ label, severity }: NetcurioChipProps) => {
	return (
		<ThemeProvider theme={theme}>
			<Chip label={label} color={severity} />
		</ThemeProvider>
	)
}
