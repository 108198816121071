import Collapse, { CollapseProps } from '@mui/material/Collapse'
import { FC } from 'react'

/**
 * NetcurioCollapse
 * @param children <ReactNode>: The content node to be collapsed.
 * @param rest <CollapseProps>: Props of the Transition component available.
 * @return component
 **/
export const NetcurioCollapse: FC<CollapseProps> = ({ children, ...rest }) => {
	return <Collapse {...rest}>{children}</Collapse>
}

NetcurioCollapse.displayName = 'NetcurioCollapse'
