import { dateFormatter, parseTimestampToTimeZone } from '@netcurio/frontend-common'
import { NetcurioTableCell, NetcurioTableRow, NetcurioTooltip } from '@netcurio/frontend-components'
import { MdRefPalette01PrimaryCLight } from '@netcurio/frontend-design-tokens'
import React, { FC } from 'react'
import { GoodReceipt } from '../../../../../types'
import Constants from '../../../../../utilities/constants'
import styles from './goodsReceiptDetail.module.scss'

interface GoodReceiptItemProps {
	goodReceipt: GoodReceipt
	position: number
	positionGRSelected: number
	showGrDetail(position: number, idGoodReceive: string): void
}
export const GoodReceiptItem: FC<GoodReceiptItemProps> = ({
	goodReceipt,
	position,
	showGrDetail,
	positionGRSelected
}) => {
	return (
		<NetcurioTableRow
			isDetailRow
			onClick={() => showGrDetail(position, goodReceipt.id)}
			style={{
				backgroundColor: positionGRSelected === position ? MdRefPalette01PrimaryCLight : ''
			}}
		>
			<NetcurioTableCell align="center">
				<p className={styles.texTableRow}>{goodReceipt?.id}</p>
			</NetcurioTableCell>
			<NetcurioTableCell align="center">
				<p className={styles.texTableRow}>
					{goodReceipt?.customer_reference || Constants.SYMBOL.HYPHEN}
				</p>
			</NetcurioTableCell>
			<NetcurioTableCell align="center">
				<p className={styles.texTableRow}>
					{dateFormatter.format(parseTimestampToTimeZone(goodReceipt?.created_at))}
				</p>
			</NetcurioTableCell>
			<NetcurioTableCell align="center">
				<NetcurioTooltip title={goodReceipt?.created_by?.email || 'n/a'} placement="left">
					<p className={styles.texTableRow}>{`${goodReceipt?.created_by?.name || ''} ${
						goodReceipt?.created_by?.lastname || ''
					}`}</p>
				</NetcurioTooltip>
			</NetcurioTableCell>
		</NetcurioTableRow>
	)
}
