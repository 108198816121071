import { Company, getCurrentUser, Roles, URLS } from '@netcurio/frontend-common'
import {
	DROPDOWN_TYPES,
	DropdownMain,
	Item,
	NetcurioGrid,
	NetcurioIcons,
	Report,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import { Auth } from 'aws-amplify'
import { t } from 'i18next'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthenticatedHeader } from '../../../components/AuthenticatedHeader/AuthenticatedHeader'
import { CancelButton } from '../../../components/HeaderButtons/CancelButton'
import ComponentQueries from '../../../components/queries'
import { StatusFilter } from '../../../types'
import { connection } from '../../../utilities/connection'
import Constants from '../../../utilities/constants'
import { showErrorComponent } from '../../../utilities/errorCode'
import { expiredToken } from '../../../utilities/expiredToken'
import { downloadExcelFile } from '../../../utilities/file-handling/download-excel-file'
import { FileDescriptor } from '../../../utilities/file-handling/file-descriptor'
import Formatter from '../../../utilities/formatter'
import UserInformation from '../../../utilities/userInformation'
import { CatchedErrorModalReports } from '../Modals/CatchedErrorModalReports'
import Queries from './queries'
import ReportDetail from './reportDetail'
import styles from './reportDetail.module.scss'

interface Props {
	title: string
	description: string
	reportType: string
	hideFilter?: boolean
	dateFilterText?: string
	statusFilterText?: string
	statusFilter?: StatusFilter[]
}

export default function ReportView({
	title,
	description,
	reportType,
	hideFilter = false,
	statusFilterText = '',
	statusFilter = [],
	dateFilterText = ''
}: Props) {
	const history = useHistory()
	const currentUser = getCurrentUser()
	const client = connection()
	const userRole = UserInformation.getCompanyRole()
	const items: Array<Report> = [
		{
			label: t('reportsText'),
			items: [
				{
					label: userRole === Roles.SUPPLIER ? t('responseTimeTitle') : t('deliveryTimeTitle'),
					url: URLS.REPORT_LIST + Constants.REPORTS.REPORT_URLS.REPORT_RESPONSE_TIME
				},
				{
					label: t('differenceBetweenProductQuantitiesTitle'),
					url:
						URLS.REPORT_LIST +
						Constants.REPORTS.REPORT_URLS.REPORT_DIFFERENCE_BETWEEN_PRODUCT_QUANTITIES
				},
				{
					label: userRole === Roles.SUPPLIER ? t('missingItemsReports') : t('missingInvoicesTitle'),
					url: URLS.REPORT_LIST + Constants.REPORTS.REPORT_URLS.REPORT_PRODUCT_MISSING_INVOICE
				},
				{
					label: t('productsCatalogTitle'),
					url: URLS.REPORT_LIST + Constants.REPORTS.REPORT_URLS.REPORT_PRODUCTS_CATALOG
				},
				{
					label: t('consignmentStockTitle'),
					url: URLS.REPORT_LIST + Constants.REPORTS.REPORT_URLS.REPORT_CONSIGNMENT_STOCK
				}
			]
		},
		{
			label: t('historicalsText'),
			items: [
				{
					label: t('historicalPurchaseOrdersTitle'),
					url: URLS.REPORT_LIST + Constants.REPORTS.REPORT_URLS.HISTORICAL_PURCHASE_ORDERS
				},
				{
					label: t('historicalInvoiceTitle'),
					url: URLS.REPORT_LIST + Constants.REPORTS.REPORT_URLS.HISTORICAL_INVOICES
				},
				{
					label: t('paymentComplementHistoryTitle'),
					url: URLS.REPORT_LIST + Constants.REPORTS.REPORT_URLS.HISTORICAL_PAYMENT_COMPLEMENT
				},
				{
					label: t('historicalCreditMemosTitle'),
					url: URLS.REPORT_LIST + Constants.REPORTS.REPORT_URLS.HISTORICAL_CREDIT_MEMOS
				},
				{
					label: t('historicalTickets'),
					url: URLS.REPORT_LIST + Constants.REPORTS.REPORT_URLS.REPORT_HISTORICAL_TICKETS
				}
			]
		}
	]
	const filterInfoCustomer = {
		name: 'supplierFilterName',
		description: 'supplierFilterDescription',
		placeholder: 'supplierFilterPlaceholder',
		checkbox: 'supplierFilterCheckbox'
	}
	const filterInfoSupplier = {
		name: 'customerFilterName',
		description: 'customerFilterDescription',
		placeholder: 'customerFilterPlaceholder',
		checkbox: 'customerFilterCheckbox'
	}

	const [errorCode, setErrorCode] = useState('')
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()

	const queryCustomersByNameOrCode = (searchText: string) => {
		return client
			.query({
				query: Queries.CUSTOMERS_BY_NAME_OR_CODE,
				variables: {
					search_text: searchText
				}
			})
			.then((result) => {
				return result.data.CustomersByNameOrCode
			})
			.catch((error) => {
				handleError(error)
			})
	}

	const querySuppliersByNameOrCode = (searchText: string) => {
		return client
			.query({
				query: ComponentQueries.SUPPLIERS_BY_NAME_OR_CODE,
				variables: {
					search_text: searchText
				}
			})
			.then((result) => {
				return result.data.SuppliersByNameOrCode
			})
			.catch((error) => {
				handleError(error)
			})
	}

	async function downloadReport(
		companySelected: Company,
		selectedStatus: string,
		startDate?: Date | Date[] | string,
		endDate?: Date | Date[] | string
	) {
		showLoadingSpinner()
		const token = await Auth.currentAuthenticatedUser()
			.then((user) => {
				return user.signInUserSession.idToken.jwtToken
			})
			.catch((err) => {
				console.error(err)
			})
		const startDateTimestamp = Formatter.initDateFormat(startDate)

		const endDateTimestamp = Formatter.finalDateFormat(endDate)

		fetch(`/api/reporting/${reportType}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json; charset=UTF-8',
				Accept: 'application/json',
				authorization: 'Bearer ' + token,
				'current-company': currentUser ? currentUser.company.rfc : undefined
			},
			body: JSON.stringify({
				companyFilter: companySelected ? companySelected.rfc : undefined,
				init_date: startDateTimestamp,
				final_date: endDateTimestamp,
				statusFilter: selectedStatus ? selectedStatus : undefined
			})
		})
			.then(async (res) => {
				if (res.ok) {
					res.json().then((responseJson: FileDescriptor) => {
						downloadExcelFile(responseJson)
					})
					hideLoadingSpinner()
				} else {
					const error = await res.json()
					handleError(error)
					hideLoadingSpinner()
				}
			})
			.catch((error) => {
				handleError(error)
				hideLoadingSpinner()
			})
	}

	function handleError(error: Error) {
		console.error(error)
		const newErrorCode = showErrorComponent(error)
		if (!expiredToken(newErrorCode)) setErrorCode(newErrorCode)
	}

	function redirectReport(item: Item | null) {
		if (item?.value) history.push(item?.value)
	}

	return (
		<NetcurioGrid
			container
			minWidth="100%"
			minHeight="100vh"
			display="grid"
			gridTemplateRows="5.5rem 1fr"
		>
			<AuthenticatedHeader>
				<div>
					<CancelButton
						onClick={() => history.push(URLS.REPORT_LIST)}
						translationKey="comeBackText"
						icon={<NetcurioIcons.ArrowBack />}
					/>
					<div className={styles.reportSelectContainer}>
						<DropdownMain
							data={items}
							menuType={DROPDOWN_TYPES.REPORT}
							defaultValue={t('changeReport')}
							onChange={redirectReport}
							label={''}
						/>
					</div>
				</div>
				<div />
			</AuthenticatedHeader>
			<ReportDetail
				title={title}
				description={description}
				filterInfo={userRole === Roles.CUSTOMER ? filterInfoCustomer : filterInfoSupplier}
				filterQuery={
					userRole === Roles.CUSTOMER ? querySuppliersByNameOrCode : queryCustomersByNameOrCode
				}
				downloadReport={downloadReport}
				hideFilter={hideFilter}
				dateFilterText={dateFilterText}
				statusFilterText={statusFilterText}
				statusFilter={statusFilter}
			/>
			<CatchedErrorModalReports open={!!errorCode} errorCode={errorCode} />
		</NetcurioGrid>
	)
}
