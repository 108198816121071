import { dateFormatterLong, getCurrentUser, parseTimestampToTimeZone, URLS } from '@netcurio/frontend-common'
import {
	NetcurioButton,
	NetcurioFormControl,
	NetcurioInputLabel,
	NetcurioMenuItem,
	NetcurioSelect,
	NetcurioSelectChangeEvent,
	NetcurioTextField,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import dayjs from 'dayjs'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { AuthenticatedHeader } from '../../components/AuthenticatedHeader/AuthenticatedHeader'
import { CancelButton } from '../../components/HeaderButtons/CancelButton'
import GlobalQueries from '../../components/queries'
import { connection } from '../../utilities/connection'
import Constants from '../../utilities/constants'
import { showErrorComponent } from '../../utilities/errorCode'
import { expiredToken } from '../../utilities/expiredToken'
import Formatter from '../../utilities/formatter'
import { AddProductModal } from './Modals/addProductModal'
import { CancelAddProductModal } from './Modals/cancelAddProductModal'
import { CatchedErrorModal } from './Modals/CatchedErrorModal'
import styles from './newProduct.module.scss'
import QueriesNewProduct from './queries'

interface CatalogueItem {
	label: string
	value: string
}
interface Status {
	label: string
	value: boolean
}

interface NewProduct {
	id: string
	ean: string
	um: string
	status: Status
	unitPrice: string
	taxIndicator: string
	currency: string
	description: string
}
interface FormErrors {
	id: boolean
	ean: boolean
	um: boolean
	unitPrice: boolean
	taxIndicator: boolean
	currency: boolean
	description: boolean
}

export function NewProduct() {
	const user = getCurrentUser()
	const { t } = useTranslation()
	const history = useHistory()
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const [productData, setProductData] = useState<NewProduct>({
		id: '',
		ean: '',
		um: 'Selecciona',
		status: { label: 'ACTIVE', value: true },
		unitPrice: '',
		taxIndicator: 'Selecciona',
		currency: 'Selecciona',
		description: ''
	})
	const [errors, setErrors] = useState<FormErrors>({
		id: false,
		ean: false,
		um: false,
		unitPrice: false,
		taxIndicator: false,
		currency: false,
		description: false
	})
	const [showMessageError, setShowMessageError] = useState<boolean>(false)
	const [measurementUnits, setAllMeasurementsUnits] = useState<CatalogueItem[]>([])
	const [currencies, setAllCurrencies] = useState<CatalogueItem[]>([])
	const [saveProductModal, setSaveProductModal] = useState<boolean>(false)
	const [cancelProductModal, setCancelProductModal] = useState<boolean>(false)
	const [disabledButton, setDisabledButton] = useState<boolean>(true)
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const [errorCode, setErrorCode] = useState<string>()

	const taxes = [
		{
			label: Formatter.percent.format(0),
			value: 0
		},
		{
			label: Formatter.percent.format(0.08),
			value: 0.08
		},
		{
			label: Formatter.percent.format(0.16),
			value: 0.16
		}
	]

	const actualStatus: Status[] = [
		{ label: 'ACTIVE', value: true },
		{ label: 'INACTIVE', value: false }
	]

	useEffect(() => {
		getAllMeasurementUnits()
		getAllCurrencies()
	}, [])

	useEffect(() => {
		const hasEmptyFields =
			productData['id'] &&
			productData['um'] !== 'Selecciona' &&
			productData['unitPrice'] &&
			productData['taxIndicator'] !== 'Selecciona' &&
			productData['currency'] !== 'Selecciona' &&
			productData['description']

		setDisabledButton(!hasEmptyFields)
	}, [productData])

	const getAllMeasurementUnits = () => {
		showLoadingSpinner()
		client
			.query({
				query: GlobalQueries.MEASUREMENT_UNITS
			})
			.then((result) => {
				const items = result.data.MeasurementUnits
				const allMeasurementUnits: CatalogueItem[] = items.map((item) => ({
					label: t(item.code),
					value: item.code
				}))

				setAllMeasurementsUnits(allMeasurementUnits)
			})
			.catch(handleError)
			.finally(() => hideLoadingSpinner())
	}

	const getAllCurrencies = () => {
		showLoadingSpinner()
		client
			.query({
				query: GlobalQueries.CURRENCIES
			})
			.then((result) => {
				const items = result.data.Currencies
				const currencies: CatalogueItem[] = items.map((item) => ({
					label: t(item.code),
					value: item.code
				}))
				setAllCurrencies(currencies)
			})
			.catch(handleError)
			.finally(() => hideLoadingSpinner())
	}

	const handleProductOnChange = (event: NetcurioSelectChangeEvent): void => {
		const idWithOutSpace = event.target.value.replace(/\s+/g, '')
		setProductData({ ...productData, id: idWithOutSpace })
	}

	const handleEanOnChange = (event: NetcurioSelectChangeEvent): void => {
		const eanRegex = /^[0-9.]+$/i
		const currentValue = event.target.value

		if (currentValue.length === 0 || (currentValue.length !== 0 && eanRegex.test(currentValue))) {
			setProductData({ ...productData, ean: currentValue })
		}
	}

	const handleUmOnChange = (event: NetcurioSelectChangeEvent): void => {
		setProductData({ ...productData, um: event.target.value })
	}

	const handleEstatusOnChange = (event: NetcurioSelectChangeEvent): void => {
		const newLabel = event.target.value
		const newValue = actualStatus.find((item) => item.label === newLabel)?.value

		setProductData({ ...productData, status: { label: newLabel, value: newValue } })
	}

	const handleUnitPriceOnChange = (event: NetcurioSelectChangeEvent): void => {
		let value = event.target.value
		value = value.replace(/\s+/g, '')
		value = value.replace(/[^0-9.]/g, '')

		if (value === '.') {
			value = '0.'
		}

		if (value.indexOf('.') !== -1) {
			value = value.slice(0, value.indexOf('.') + 3)
		}
		setProductData({ ...productData, unitPrice: value })
	}

	const handleTaxOnChange = (event: NetcurioSelectChangeEvent): void => {
		setProductData({ ...productData, taxIndicator: event.target.value.toString() })
	}

	const handleCurrencyOnChange = (event: NetcurioSelectChangeEvent): void => {
		setProductData({ ...productData, currency: event.target.value })
	}

	const handleProductDescriptionOnChange = (event: NetcurioSelectChangeEvent): void => {
		setProductData({ ...productData, description: event.target.value })
	}

	const validateForm = () => {
		const newErrors = { ...errors }
		let isValidForm = true

		if (!productData['id']) {
			isValidForm = false
			newErrors.id = true
		} else {
			newErrors.id = false
		}
		if (!productData['um']) {
			isValidForm = false
			newErrors.um = true
		} else if (productData['um'] === 'Selecciona') {
			isValidForm = false
			newErrors.um = true
		} else {
			newErrors.um = false
		}
		if (!productData['unitPrice']) {
			isValidForm = false
			newErrors.unitPrice = true
		} else {
			newErrors.unitPrice = false
		}
		if (!productData['taxIndicator']) {
			isValidForm = false
			newErrors.taxIndicator = true
		} else if (productData['taxIndicator'] === 'Selecciona') {
			isValidForm = false
			newErrors.taxIndicator = true
		} else {
			newErrors.taxIndicator = false
		}
		if (!productData['currency']) {
			isValidForm = false
			newErrors.currency = true
		} else if (productData['currency'] === 'Selecciona') {
			isValidForm = false
			newErrors.currency = true
		} else {
			newErrors.currency = false
		}
		if (!productData['description']) {
			isValidForm = false
			newErrors.description = true
		} else {
			newErrors.description = false
		}

		setErrors(newErrors)
		setShowMessageError(!isValidForm)

		return isValidForm
	}

	const saveProduct = () => {
		if (validateForm()) {
			const unitPriceToFloat = parseFloat(productData['unitPrice'].replace(/\$|,/g, ''))
			const taxToFloat = parseFloat(productData['taxIndicator'])
			setSaveProductModal(false)
			showLoadingSpinner()
			client
				.mutate({
					mutation: QueriesNewProduct.CREATE_PRODUCT,
					variables: {
						id: productData['id'],
						description: productData['description'],
						currency: productData['currency'],
						unit_price: unitPriceToFloat,
						unit: productData['um'],
						is_active: productData['status']['value'],
						tax: taxToFloat,
						ean: productData['ean'].trim() || null
					}
				})
				.then(() => {
					history.push(
						`${URLS.ADMINISTRATION}/${Constants.ADMIN.ADMIN_TABS.PRODUCTS_TAB}/${user?.company.rfc}&${productData['id']}`
					)
				})
				.catch(handleError)
				.finally(() => {
					setSaveProductModal(false)
					hideLoadingSpinner()
				})
		} else {
			setSaveProductModal(false)
		}
	}

	const handleError = (error: Error) => {
		const newErrorCode = showErrorComponent(error)
		if (!expiredToken(newErrorCode)) {
			setErrorCode(newErrorCode)
		}
	}

	const cancelProduct = () => {
		history.push(`${URLS.ADMINISTRATION}/${Constants.ADMIN.ADMIN_TABS.PRODUCTS_TAB}`)

		setCancelProductModal(false)
	}

	return (
		<div className={styles.newProductMainContainer}>
			<AuthenticatedHeader>
				<div>
					<CancelButton onClick={() => setCancelProductModal(true)} />
				</div>
				<div />
			</AuthenticatedHeader>
			<div className={styles.cardBackgroundNewProduct}>
				<div className={styles.headerNewProduct}>
					<p className={styles.alignmentTitleNewProduct}>{t('titleNewProduct')}</p>
				</div>
				<div className={styles.subtitleContainer}>
					<p className={styles.text}>{t('subtitleNewProduct')}</p>
				</div>
				<div className={styles.informationNewProductContainer}>
					<div className={styles.adjustContainer}>
						<div className={styles.textInputNewProduct}>
							<NetcurioTextField
								label={t('textInputProduct')}
								size="small"
								fullWidth
								value={productData['id']}
								error={errors['id']}
								placeholder={t('placeholderProduct')}
								onChange={(event) => handleProductOnChange(event)}
							/>
						</div>
						<div className={styles.textInputNewProduct}>
							<NetcurioTextField
								label={t('ean_product')}
								size="small"
								fullWidth
								placeholder={t('enterEANCode')}
								value={productData['ean']}
								error={errors['ean']}
								onChange={(event) => handleEanOnChange(event)}
							/>
						</div>
						<div className={styles.textInputNewProduct}>
							<NetcurioTextField
								label={t('createdBy_product')}
								size="small"
								fullWidth
								value={`${user.name} ${user.lastname}`}
								disabled={true}
							/>
						</div>
						<div className={styles.textInputNewProduct}>
							<NetcurioFormControl fullWidth>
								<NetcurioInputLabel id="unitMeasure" error={errors['um']}>
									{t('unitMeasure')}
								</NetcurioInputLabel>
								<NetcurioSelect
									labelId="unitMeasure"
									variant="outlined"
									label={t('unitMeasure')}
									displayEmpty
									fullWidth
									size="medium"
									value={productData['um']}
									onChange={(event) => handleUmOnChange(event)}
									error={errors['um']}
									height="smaller"
								>
									<NetcurioMenuItem value="Selecciona">
										<em>{t('placeholderSelect')}</em>
									</NetcurioMenuItem>
									{measurementUnits.map((item, i) => (
										<NetcurioMenuItem key={i} value={item.value}>
											<span>{item.label}</span>
										</NetcurioMenuItem>
									))}
								</NetcurioSelect>
							</NetcurioFormControl>
						</div>
						<div className={styles.textInputNewProduct}>
							<NetcurioFormControl fullWidth>
								<NetcurioInputLabel id="status_product">
									{t('status_product')}
								</NetcurioInputLabel>
								<NetcurioSelect
									labelId="status_product"
									label={t('status_product')}
									variant="outlined"
									displayEmpty
									fullWidth
									value={productData['status'].label}
									onChange={(event) => handleEstatusOnChange(event)}
								>
									{actualStatus.map((item, i) => (
										<NetcurioMenuItem key={i} value={item.label}>
											<span>{t(item.label)}</span>
										</NetcurioMenuItem>
									))}
								</NetcurioSelect>
							</NetcurioFormControl>
						</div>
						<div className={styles.textInputNewProduct}>
							<NetcurioTextField
								label={t('creationDate_product')}
								size="small"
								fullWidth
								value={dateFormatterLong.format(parseTimestampToTimeZone(dayjs().toDate()))}
								disabled
							/>
						</div>
						<div className={styles.textInputNewProduct}>
							<NetcurioTextField
								label={t('unitPrice')}
								error={errors['unitPrice']}
								size="small"
								fullWidth
								id={'unitPriceInputField'}
								placeholder={'$ 0.00'}
								value={productData['unitPrice']}
								onChange={(event) => handleUnitPriceOnChange(event)}
								onBlur={(event) => {
									setProductData({
										...productData,
										unitPrice: Formatter.currency.format(event.target.value)
									})
								}}
								onFocus={(event) => {
									setProductData({
										...productData,
										unitPrice: event.target.value.replace(/\$|,/g, '')
									})
								}}
							/>
						</div>
						<div className={styles.textInputNewProduct}>
							<NetcurioFormControl fullWidth error={errors['taxIndicator']}>
								<NetcurioInputLabel id="taxIndicator_product">
									{t('taxIndicator_product')}
								</NetcurioInputLabel>
								<NetcurioSelect
									labelId="taxIndicator_product"
									label={t('taxIndicator_product')}
									variant="outlined"
									size="medium"
									value={productData['taxIndicator']}
									onChange={(event) => handleTaxOnChange(event)}
									error={errors['taxIndicator']}
									height={'smaller'}
								>
									<NetcurioMenuItem value="Selecciona">
										<em>{t('placeholderSelect')}</em>
									</NetcurioMenuItem>
									{taxes.map((item, i) => (
										<NetcurioMenuItem key={i} value={item.value}>
											<span>{item.label}</span>
										</NetcurioMenuItem>
									))}
								</NetcurioSelect>
							</NetcurioFormControl>
						</div>
						<div className={styles.textInputNewProduct}>
							<NetcurioFormControl fullWidth error={errors['currency']}>
								<NetcurioInputLabel id="currency">{t('currencyText')}</NetcurioInputLabel>
								<NetcurioSelect
									labelId="currency"
									label={t('currencyText')}
									variant="outlined"
									size="medium"
									value={productData['currency']}
									onChange={(event) => handleCurrencyOnChange(event)}
									error={errors['currency']}
									height={'smaller'}
								>
									<NetcurioMenuItem value="Selecciona">
										<em>{t('placeholderSelect')}</em>
									</NetcurioMenuItem>
									{currencies.map((item, i) => (
										<NetcurioMenuItem key={i} value={item.value}>
											<span>{item.label}</span>
										</NetcurioMenuItem>
									))}
								</NetcurioSelect>
							</NetcurioFormControl>
						</div>
					</div>
					<div className={styles.descriptionContainer}>
						<NetcurioTextField
							label={t('descriptionText')}
							height="xl"
							variant="outlined"
							fullWidth
							value={productData['description']}
							error={errors['description']}
							placeholder={t('placeholderDescription')}
							onChange={(event) => handleProductDescriptionOnChange(event)}
							multiline
							rows={5}
						/>
					</div>
				</div>
				{showMessageError && <div className={styles.missingFieldMessage}>{t('missingFields')}</div>}
				<NetcurioButton
					variant={'outlined'}
					size={'small'}
					sx={{ margin: '2rem 0 1rem 2rem' }}
					onClick={() => setSaveProductModal(true)}
					disabled={disabledButton}
				>
					{t('textAddButton')}
				</NetcurioButton>
				<AddProductModal
					open={saveProductModal}
					onAccept={() => saveProduct()}
					onClose={() => setSaveProductModal(false)}
				/>
				<CancelAddProductModal
					open={cancelProductModal}
					onAccept={() => cancelProduct()}
					onClose={() => setCancelProductModal(false)}
				/>
				<CatchedErrorModal open={!!errorCode} errorCode={errorCode} />
			</div>
		</div>
	)
}
