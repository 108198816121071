import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface ValidationRules {
	required?: boolean
	minLength?: number
	requiredMessage?: string
	minLengthMessage?: string
	[key: string]: any
}

type ValidationSchema<T> = {
	[K in keyof T]?: ValidationRules
}

type UseFormReturn<T> = {
	values: T
	errors: Partial<Record<keyof T, string>>
	isSubmitting: boolean
	handleChange: (name: keyof T, value: string) => void
	handleSubmit: (e: React.FormEvent, validationSchema?: ValidationSchema<T>) => Promise<void>
	resetForm: (values?: T) => void
	updateValues: (updates: Partial<T>) => void
	setErrors: (errors: Partial<Record<keyof T, string>>) => void
}

const useForm = <T extends Record<string, any>>(
	initialValues: T,
	onSubmitCallback?: (values: T) => Promise<void> | void
): UseFormReturn<T> => {
	const [values, setValues] = useState<T>(initialValues)
	const [errors, setErrors] = useState<Partial<Record<keyof T, string>>>({})
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
	const { t } = useTranslation()

	const handleChange = (name: keyof T, value: string): void => {
		setValues((prevValues) => ({
			...prevValues,
			[name]: value
		}))
	}

	const validate = (validationSchema?: ValidationSchema<T>): boolean => {
		if (!validationSchema) return true

		const newErrors: Partial<Record<keyof T, string>> = {}

		for (const field in validationSchema) {
			const rules = validationSchema[field]
			const value = values[field as keyof T]

			if (rules?.required && !value) {
				newErrors[field as keyof T] = rules.requiredMessage || t('requiredField')
			}

			if (rules?.minLength && value && value.length < rules.minLength) {
				newErrors[field as keyof T] = rules.minLengthMessage || `${field} is too short`
			}

			if (rules?.validator && value && !rules.validator(value)) {
				newErrors[field as keyof T] = rules.errorMessage
			}
		}

		setErrors(newErrors)
		return Object.keys(newErrors).length === 0
	}

	const handleSubmit = async (
		e: React.FormEvent,
		validationSchema?: ValidationSchema<T>
	): Promise<void> => {
		e.preventDefault()

		const isValid = validate(validationSchema)

		if (isValid) {
			setIsSubmitting(true)

			try {
				await onSubmitCallback?.(values)
			} catch (err) {
				console.error('Form submission error:', err)
			} finally {
				setIsSubmitting(false)
			}
		}
	}

	const resetForm = (values?: T): void => {
		setValues(values ?? initialValues)
		setErrors({})
	}

	const updateValues = (updates: Partial<T>): void => {
		setValues((prevValues) => ({
			...prevValues,
			...updates
		}))
	}

	return {
		values,
		errors,
		isSubmitting,
		handleChange,
		handleSubmit,
		resetForm,
		updateValues,
		setErrors
	}
}

export default useForm
