import { Roles } from '@netcurio/frontend-common'
import Constants from '../../../../utilities/constants'
import UserInformation from '../../../../utilities/userInformation'
import styles from '../invoicesList.module.scss'
import { DataInvoiceList, TypesDataTable, TypesStyleMainTable } from '../types'
import { objectPcFilterArray, objectStatusFilterArray } from './tableParameterArray'

const userRole: Roles = UserInformation.getCompanyRole()

export const invoicesListValues: DataInvoiceList = {
	rowsActual: undefined,
	totalRows: undefined,
	filterContainerBar: false,
	dataFiltersArray: [],
	dataFilters: {},
	deleteRange: false,
	filtersOfTypeStatus: {
		status: {
			list: objectStatusFilterArray(userRole),
			numSelectOptions: 7,
			dataFilter: ''
		}
	},
	filtersOfTypePaymentComp: {
		status: {
			list: objectPcFilterArray(),
			numSelectOptions: 2,
			dataFilter: ''
		}
	},
	limitRows: undefined,
	fieldList: 'created_at',
	orderList: Constants.ORDER.DESC,
	actualFilterData: undefined,
	dataInvoice: {},
	currency: ''
}

export const dataTableInvoiceValue: TypesDataTable = {
	fillStatusInputFilter: [],
	fillPcInputFilter: [],
	numericFilter: 'display-none',
	dateFilter: 'display-none',
	proposedDateFilter: 'display-none',
	statusFilter: 'display-none',
	textFilter: 'display-none',
	hasRelationFilter: 'display-none',
	headerMainTableInvoicesClick: styles.headerMainTableInvoices,
	initRange: undefined,
	finalRange: undefined,
	initPropRange: undefined,
	finalPropRange: undefined,
	initDate: undefined,
	finalDate: undefined,
	initPropDate: undefined,
	finalPropDate: undefined,
	minAmount: '',
	maxAmount: '',
	minRange: undefined,
	maxRange: undefined,
	deleteRange: false,
	actualField: 'created_at',
	filterValue: '',
	textHeader: styles.textheaderListInvoices,
	initDateErrorText: '',
	finalDateErrorText: '',
	initPropDateErrorText: '',
	finalPropDateErrorText: '',
	initPropDateStyleErrorText: '',
	finalPropDateStyleErrorText: ''
}

export const stylesTableValue: TypesStyleMainTable = {
	mainContainerHeight: 0,
	mainTableHeight: 0,
	activeHeight: true
}

export const themeButtonHeader = {
	fontSize: '1.2rem',
	color: Constants.COLORS.GREY_PEBBLE,
	borderRadius: '.5rem',
	textTransform: 'inherit',
	padding: '0 1rem',
	border: `.1rem solid ${Constants.COLORS.GREEN_INVOICES}`,
	'&:hover': {
		color: 'white',
		background: Constants.COLORS.GREEN_INVOICES,
		border: `.1rem solid ${Constants.COLORS.GREEN_INVOICES}`
	}
}

export const themeButtonsWithIconHeader = {
	fontSize: '1.2rem',
	color: Constants.COLORS.GREY_PEBBLE,
	borderRadius: '.5rem',
	padding: '0.7rem 0.6rem',
	border: `.1rem solid ${Constants.COLORS.GREEN_INVOICES}`,
	minWidth: 'auto',
	width: '3rem',
	height: '3rem',
	'&:hover': {
		color: 'white',
		background: Constants.COLORS.GREEN_INVOICES,
		border: `.1rem solid ${Constants.COLORS.GREEN_INVOICES}`
	}
}

export const themeButtonComeBack = {
	fontSize: '1.2rem',
	color: Constants.COLORS.GREY_PEBBLE,
	borderRadius: '.5rem',
	textTransform: 'inherit',
	padding: '0 1rem',
	border: `.1rem solid ${Constants.COLORS.RED_COLOR_ERROR_BORDER}`,
	height: '3rem',
	minWidth: 'auto',
	'&:hover': {
		color: 'white',
		background: Constants.COLORS.RED_COLOR_ERROR_BORDER,
		border: `.1rem solid ${Constants.COLORS.RED_COLOR_ERROR_BORDER}`
	}
}

export const themeIconUnarchived = {
	color: Constants.COLORS.GREEN_INVOICES,
	fontSize: '2rem',
	borderRadius: '1px solid black'
}
