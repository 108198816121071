import gql from 'graphql-tag'
export const UPDATE_INVOICES_TO_PAID = gql`
	mutation updateInvoicesToPaid($invoicesIds: [String!]!, $payment_date: Date!) {
		updateInvoicesToPaid(invoicesIds: $invoicesIds, payment_date: $payment_date) {
			code
			message
			counter
		}
	}
`
