import { PURCHASE, Roles, tableFillSelectionFilter } from '@netcurio/frontend-common'
import { t } from 'i18next'
import Constants from '../../../../utilities/constants'
import { tableFillHeader } from '../../../../utilities/tableFillHeader'

export function objectHeaderArray(userRole: Roles): Array<tableFillHeader> {
	const serieFolioColumn = new tableFillHeader(
		t('registerIdentifier'),
		undefined,
		'',
		'serie' + 'folio',
		'text'
	)
	const statusColumn = new tableFillHeader(t('actualState'), undefined, '', 'status', 'status')
	const proposedDateColumn = new tableFillHeader(
		t('proposedPaymentDate'),
		undefined,
		'',
		'proposed_payment_date',
		'proposedDate'
	)
	const customerColumn = new tableFillHeader(t('buyer'), undefined, '', 'receiver', 'text')
	const supplierColumn = new tableFillHeader(t('seller'), undefined, '', 'sender', 'text')
	const statusDetailColumn = new tableFillHeader(
		t('invoices.statusDetail'),
		undefined,
		'',
		'statusDetail',
		'text'
	)
	const currencyColumn = new tableFillHeader(t('currencyText'), undefined, '', 'currency', 'text')
	const quantityColumn = new tableFillHeader(t('totalToPay'), undefined, '', 'total', 'numeric')
	const relatedDocumentColumn = new tableFillHeader(
		t('relatedSaleIdentifier'),
		undefined,
		'',
		'reference',
		'text'
	)
	const paymentComplementColumn = new tableFillHeader(
		t('relatedPaymentComplement'),
		undefined,
		'',
		'payment_complement',
		'hasRelation'
	)
	const objectHeaderArraySupplier = [
		serieFolioColumn,
		statusColumn,
		statusDetailColumn,
		customerColumn,
		proposedDateColumn,
		relatedDocumentColumn,
		paymentComplementColumn,
		quantityColumn,
		currencyColumn
	]
	const objectHeaderArrayCustomer = [
		serieFolioColumn,
		statusColumn,
		statusDetailColumn,
		supplierColumn,
		proposedDateColumn,
		relatedDocumentColumn,
		paymentComplementColumn,
		quantityColumn,
		currencyColumn
	]
	if (userRole === Roles.CUSTOMER) {
		return objectHeaderArrayCustomer
	} else {
		return objectHeaderArraySupplier
	}
}
export function objectStatusFilterArray(userRole: Roles): Array<tableFillSelectionFilter> {
	const missingStatusOption = new tableFillSelectionFilter(
		Constants.LINKS.MISSING_LINK,
		'payment-status-option'
	)
	const paymentScheduledStatusOption = new tableFillSelectionFilter(
		PURCHASE.PAYMENT_SCHEDULED,
		'payment-scheduled-status-option'
	)
	const paidStatusOption = new tableFillSelectionFilter(PURCHASE.PAID, 'paid-status-option')
	const errorStatusOption = new tableFillSelectionFilter(Constants.ERROR.ERROR, 'error-status-option')
	const canceledStatusOption = new tableFillSelectionFilter(PURCHASE.CANCELED, 'canceled-status-option')
	const voidedStatusOption = new tableFillSelectionFilter(PURCHASE.VOIDED, 'voided-status-option')
	const inProcessStatusOption = new tableFillSelectionFilter('IN_PROCESS', 'payment-status-option')
	const inApprovalStatusOption = new tableFillSelectionFilter('IN_APPROVAL', 'in_approval-status-option')
	const objectStatusSupplierOptions = [
		missingStatusOption,
		paidStatusOption,
		paymentScheduledStatusOption,
		errorStatusOption,
		canceledStatusOption,
		voidedStatusOption,
		inProcessStatusOption,
		inApprovalStatusOption
	]
	const objectStatusCustomerOptions = [
		missingStatusOption,
		paidStatusOption,
		paymentScheduledStatusOption,
		errorStatusOption,
		canceledStatusOption,
		voidedStatusOption,
		inProcessStatusOption,
		inApprovalStatusOption
	]
	if (userRole === Roles.CUSTOMER) {
		return objectStatusCustomerOptions
	} else {
		return objectStatusSupplierOptions
	}
}

export function objectPcFilterArray(): Array<tableFillSelectionFilter> {
	const hasRelation = new tableFillSelectionFilter(true, '') // t('invoices.true')
	const doesntHaveRelation = new tableFillSelectionFilter(false, '')
	return [hasRelation, doesntHaveRelation]
}
